import React, { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { appConfig } from "../../config";
import {
  GoodsStateMessage,
  CustomerStateMessage,
  StoreStateMessage,
  OnlineCustomerStateMessage,
  AtgStateMessage,
  SimulationMessage,
  InitAnswer,
  StorageStateMessage
} from "../../models/messageSpec";
import SimulationSharedProps, {
  StoreInfo
} from "../../types/SimulationSharedProps";
import {
  FrontendMessage,
  StartSimulation
} from "../../models/frontentMessageSpec";
import { SidebarContentType } from "../../types/SidebarTypes";

interface SimProviderProps {
  children: (props: SimulationSharedProps) => React.ReactNode;
}

export const PLAYING_BASESPEED = 1000; // ms between each play step
const BUFFER_WAITIME = 1000; // ms between each ask for new data
const SIMULATION_FINISHED = 50; // nuymber of getData messages without new data until simulation is determined as having finished

export default function SimulationProvider({
  children
}: SimProviderProps): React.ReactNode {
  // variables for shared prop
  const [simulationPaused, setSimulationPaused] = useState<boolean>();
  const [currentCycle, setCurrentCycle] = useState<number | null>(null);
  const [simulationSpeed, setSimulationSpeed] = useState(1);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false); // Drawer-Zustand hinzugefügt
  const [cycleList, setCycleList] = useState<number[]>([]);
  const [cycleTimeLookup, setCycleTimeLookup] = useState<{
    [k: number]: string;
  }>({});
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [goodsStateMessages, setGoodsStateMessage] = useState<
    GoodsStateMessage[]
  >([]);
  const [customerStateMessages, setCustomerStateMessage] = useState<
    CustomerStateMessage[]
  >([]);
  const [storeStateMessages, setStoreStateMessage] = useState<
    StoreStateMessage[]
  >([]);
  const [onlineCustomerStateMessages, setOnlineCustomerStateMessage] = useState<
    OnlineCustomerStateMessage[]
  >([]);
  const [atgStateMessages, setAtgStateMessages] = useState<AtgStateMessage[]>(
    []
  );
  const [storageStateMessages, setStorageStateMessage] = useState<
    StorageStateMessage[]
  >([]);
  const [sidebarContentType, setSidebarContentType] =
    useState<SidebarContentType>(SidebarContentType.NONE);
  const [sidebarObjectId, setSidebarObjectId] = useState<string[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreInfo>();
  const [availableStores, setAvailableStores] = useState<StoreInfo[]>([]);
  const [waitingForData, setWaitingForData] = useState<boolean>(false);
  // variables for logic in SimulationProvider
  const { sendMessage, lastMessage } = useWebSocket(
    `${appConfig.webSocketURL}`
  );
  const playingIntervalId = useRef<NodeJS.Timeout>();
  const simulationData = useRef<Array<SimulationMessage>>(null);
  const getDataInterval = useRef<NodeJS.Timeout>();
  const numberOfUnchangedMessages = useRef<number>(0);
  const initGameSent = useRef(false);
  // Websocket Handshake und init Game
  useEffect(() => {
    if (!initGameSent.current) {
      const initGame: FrontendMessage = {
        messageType: "initGame"
      };
      sendMessage(JSON.stringify(initGame));
      initGameSent.current = true; // Mark as sent
    }
  }, [sendMessage]);

  // Daten vom Backend empfangen
  useEffect(() => {
    if (lastMessage) {
      const parsedMessage = JSON.parse(lastMessage.data as string);
      if (parsedMessage.message_type === "initGame") {
        const initAnswer = parsedMessage as InitAnswer;
        const storeInfos: StoreInfo[] = Object.keys(initAnswer.store_info).map(
          (storeId) => {
            return {
              id: storeId,
              name: initAnswer.store_info[storeId].store_name,
              opening: initAnswer.store_info[storeId].Opening,
              closing: initAnswer.store_info[storeId].Closing
            };
          }
        );
        setAvailableStores(storeInfos);
      } else {
        simulationData.current = parsedMessage as SimulationMessage[];
        // get all available cycles and put them into a list
        const cycles: number[] = parsedMessage.map((msg) => msg.cycle);
        const uniqueSortedCycles: number[] = Array.from(new Set(cycles)).sort(
          (a, b) => a - b
        );
        setCycleList(uniqueSortedCycles);
        // if cycle is not set or doesn't exist anymore, set it to the first cycle
        if (!currentCycle || !uniqueSortedCycles.includes(currentCycle)) {
          setCurrentCycle(uniqueSortedCycles[0]);
        }
        // create a mapping that maps cycle numers to times
        const cycleTimeMap: { [k: number]: string } = {};
        parsedMessage.forEach((msg: SimulationMessage) => {
          cycleTimeMap[msg.cycle] = msg.current_time;
        });
        setCycleTimeLookup(cycleTimeMap);
        // stop the spinning
        setWaitingForData(false);
      }
    }
  }, [lastMessage]);

  // sorting the messages into their bucket
  useEffect(() => {
    if (simulationData.current !== null) {
      // initialize lists for messages
      const goodsMessages: GoodsStateMessage[] = [];
      const customerMessages: CustomerStateMessage[] = [];
      const storeMessages: StoreStateMessage[] = [];
      const onlineCustomerMessage: OnlineCustomerStateMessage[] = [];
      const atgStateMessages: AtgStateMessage[] = [];
      const storageMessages: StorageStateMessage[] = [];
      // put messages into respective array
      simulationData.current.forEach((item, index) => {
        switch (item.message_type) {
          case "GoodsStateMessage":
            goodsMessages.push(item as GoodsStateMessage);
            break;
          case "CustomerStateMessage":
            customerMessages.push(item as CustomerStateMessage);
            break;
          case "StoreStateMessage":
            storeMessages.push(item as StoreStateMessage);
            break;
          case "OnlineCustomerStateMessage":
            onlineCustomerMessage.push(item as OnlineCustomerStateMessage);
            break;
          case "ATGStateMessage":
            atgStateMessages.push(item as AtgStateMessage);
            break;
          case "StorageStateMessage":
            storageMessages.push(item as StorageStateMessage);
            break;
          default:
            console.warn(`Unknown message type at index ${index}:`, item);
        }
      });
      setGoodsStateMessage(goodsMessages);
      setCustomerStateMessage(customerMessages);
      setStoreStateMessage(storeMessages);
      setOnlineCustomerStateMessage(onlineCustomerMessage);
      setAtgStateMessages(atgStateMessages);
      setStorageStateMessage(storageMessages);
      // check if there were new messages
      if (
        goodsMessages.length === goodsStateMessages.length &&
        customerMessages.length === customerStateMessages.length &&
        storeMessages.length === storeStateMessages.length &&
        onlineCustomerMessage.length === onlineCustomerStateMessages.length
      ) {
        numberOfUnchangedMessages.current += 1;
      } else {
        numberOfUnchangedMessages.current = 0;
      }
    }
  }, [simulationData.current]);

  // send start simulation message as soon as start date and store are set
  const sendStartSimulation = useCallback(() => {
    if (startDate && selectedStore) {
      const data: StartSimulation = { startDate, store: selectedStore.id };
      const startSimMessage: FrontendMessage = {
        messageType: "startSimulation",
        data: data
      };
      sendMessage(JSON.stringify(startSimMessage));
      // start the spinning
      setWaitingForData(true);
      // start periodic asks for new data
      getDataInterval.current = setInterval(() => {
        if (numberOfUnchangedMessages.current <= SIMULATION_FINISHED) {
          getDataFromBackend();
        } else {
          clearInterval(getDataInterval.current);
          getDataInterval.current = null;
        }
      }, BUFFER_WAITIME);
    }
  }, [startDate, selectedStore]);

  // cleanup getData interval to avoid memory leak
  useEffect(() => {
    return () => {
      if (getDataInterval.current) {
        clearInterval(getDataInterval.current);
        getDataInterval.current = null;
      }
    };
  }, []);

  // Funktion zum Abrufen von Daten vom Backend
  const getDataFromBackend = () => {
    if (startDate) {
      const getData: FrontendMessage = {
        messageType: "getData"
      };
      sendMessage(JSON.stringify(getData));
    }
  };

  // start and stop if isPlaying changes
  useEffect(() => {
    if (isPlaying) {
      handlePlay();
    } else {
      handlePause();
    }
    return () => {
      clearInterval(playingIntervalId.current);
    };
  }, [isPlaying]);
  // Funktion zum Starten des Play - Modus
  const handlePlay = () => {
    const minCycle = cycleList.length > 0 ? cycleList[0] : 0;
    const maxCycle = cycleList.length > 0 ? cycleList[cycleList.length - 1] : 0;
    if (playingIntervalId.current || minCycle === null || maxCycle === null)
      return;

    setIsPlaying(true);
    playingIntervalId.current = setInterval(() => {
      setCurrentCycle((prevCycle) => {
        // Hier sicherstellen, dass prevCycle innerhalb des gültigen Bereichs ist
        if (prevCycle < maxCycle) {
          return prevCycle + 1;
        } else {
          clearInterval(playingIntervalId.current);
          playingIntervalId.current = null;
          setIsPlaying(false);
          return maxCycle; // Gibt maxCycle zurück, wenn der Zyklus das Maximum erreicht hat
        }
      });
    }, PLAYING_BASESPEED / simulationSpeed);
  };
  // Funktion zum Stoppen des Play-Modus
  const handlePause = () => {
    if (playingIntervalId.current) {
      clearInterval(playingIntervalId.current);
      playingIntervalId.current = null;
      setIsPlaying(false);
    }
  };

  // change the speed of the simulation according to the set variable
  useEffect(() => {
    if (isPlaying) {
      // clear the loop and restart it with the changed speed
      clearInterval(playingIntervalId.current);
      playingIntervalId.current = null;
      handlePlay();
    }
    return () => {
      clearInterval(playingIntervalId.current);
    };
  }, [simulationSpeed]);

  const sharedProps: SimulationSharedProps = {
    simulationPaused: simulationPaused,
    setSimulationPaused: setSimulationPaused,
    currentCycle: currentCycle,
    setCurrentCycle: setCurrentCycle,
    simulationSpeed: simulationSpeed,
    setSimulationSpeed: setSimulationSpeed,
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    drawerVisible: drawerVisible,
    setDrawerVisible: setDrawerVisible,
    cycleList: cycleList,
    setCycleList: setCycleList,
    cycleTimeLookup: cycleTimeLookup,
    isPlaying: isPlaying,
    setIsPlaying: setIsPlaying,
    storeStateMessages: storeStateMessages,
    customerStateMessages: customerStateMessages,
    goodsStateMessages: goodsStateMessages,
    onlineCustomerMessages: onlineCustomerStateMessages,
    atgStateMessages: atgStateMessages,
    storageStateMessages: storageStateMessages,
    sidebarContentType: sidebarContentType,
    setSidebarContentType: setSidebarContentType,
    sidebarObjectId: sidebarObjectId,
    setSidebarObjectId: setSidebarObjectId,
    selectedStore: selectedStore,
    setSelectedStore: setSelectedStore,
    availableStores: availableStores,
    sendStartSimulationMessage: sendStartSimulation,
    waitingForData: waitingForData
  };
  return <>{children(sharedProps)}</>;
}
