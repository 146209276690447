import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Typography } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  WalletOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";

const { Text } = Typography;

export const picRequestConfig = {
  scopes: ["User.Read"]
};

// takes access token and fetches a GET request to the msal graph api url
async function getMsUserPhotoUrl(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
    responseType: "blob"
  };

  return fetch(
    "https://graph.microsoft.com/v1.0/me/photos/64x64/$value",
    options
  )
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(blob);
      return blobUrl;
    })
    .catch((error) => console.log(error));
}

export default function ProfileMenuDropdown() {
  const MsalContext = useMsal();
  const [imageUrl, setImageUrl] = useState<string>(null);

  const signOutClickHandler = () => {
    const logoutRequest = {
      account: MsalContext.instance.getActiveAccount(),
      postLogoutRedirectUri: "/login"
    };
    MsalContext.instance.logoutRedirect(logoutRequest);
  };

  useEffect(() => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    MsalContext.instance
      .acquireTokenSilent({
        ...picRequestConfig,
        account: MsalContext.accounts[0]
      })
      .then((response) => {
        getMsUserPhotoUrl(response.accessToken).then((blobUrl) =>
          setImageUrl(blobUrl as string)
        );
      });
  }, [MsalContext.accounts, MsalContext.instance]);

  const profileMenuItems = [
    { label: "Profile", icon: <UserOutlined />, key: "0" },
    { label: "Settings", icon: <SettingOutlined />, key: "1" },
    { label: "Billing", icon: <WalletOutlined />, key: "2" },
    { type: "divider" as const },
    {
      label: <Text onClick={signOutClickHandler}>Logout</Text>,
      icon: <LogoutOutlined />,
      key: "3"
    }
  ];
  return (
    <Dropdown menu={{ items: profileMenuItems }} placement="bottomRight">
      <Avatar src={imageUrl} />
    </Dropdown>
  );
}
