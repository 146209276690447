import React from "react";
import styles from "../../../../styles/SimulationEngine/Store/SideDrawer/StatsCardsWoolworth.module.css";
import { ArrowDown } from "react-bootstrap-icons";
import { Typography } from "antd";
import { Tiny } from "@ant-design/plots";

const { Title, Text } = Typography;

interface StatsCardSimpleProps {
  icon: React.ReactNode; // Für den Smiley oder jedes andere Icon
  title: string; // Für den Titel
  value: number | string; // Für die Zahlenanzeige
}
interface StatsCardRingProps {
  title: string;
  percent: number;
}

export function StatsCardSimple({
  icon = <ArrowDown className={styles["iconSmiley"]} />, // Standardicon, wenn kein anderes übergeben wird
  title,
  value
}: StatsCardSimpleProps): React.ReactNode {
  return (
    <div className={styles["standardDowndefault"]}>
      <div className={styles["defaultUpperThirdNoLine"]}>
        <div className={styles["iconBoxBlue"]}>
          {React.cloneElement(icon as React.ReactElement, {
            className: styles["iconSmiley"]
          })}
        </div>
        <div className={styles["TextBox"]}>
          <Text className={styles["StatisticTitle"]}>{title}</Text>
          <Title className={styles["StatsNumbers"]} level={5}>
            {value}
          </Title>
        </div>
      </div>
    </div>
  );
}

export function StatsCardRing({
  title,
  percent
}: StatsCardRingProps): React.ReactNode {
  if (!percent || percent === 0) {
    percent = 0.00001; // otherwise no ring gets displayed
  }
  const primarycolor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--Colors-Brand-Primary-colorPrimary");
  const ringbackgroundcolor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--Colors-Brand-Primary-colorPrimaryBgHover");
  // const ringconfig = {
  //   percent,
  //   width: 36,
  //   height: 36,
  //   color: [ringbackgroundcolor, primarycolor]
  // };
  const ringconfig = {
    percent,
    width: 36,
    height: 36,
    color: [ringbackgroundcolor, primarycolor]
  };
  const percentageText = Math.round(percent * 100).toString() + " %";
  return (
    <div className={styles["standardDowndefault"]}>
      <div className={styles["defaultUpperThirdNoLine"]}>
        <div className={styles["ringBox"]}>
          <Tiny.Ring {...ringconfig} />
        </div>
        <div className={styles["TextBox"]}>
          <Text className={styles["StatisticTitle"]}>{title}</Text>
          <Title className={styles["StatsNumbers"]} level={5}>
            {percentageText}
          </Title>
        </div>
      </div>
    </div>
  );
}
