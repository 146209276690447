import React, { useEffect, useState } from "react";
import styles from "../../../../styles/SimulationEngine/Store/OfflineStore/Customer.module.css";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import {
  CoordinatePoint,
  CustomerInstance,
  PickerInstance
} from "../../../../models/messageSpec";
import { motion, useAnimate } from "framer-motion";
import useResizeObserver from "@react-hook/resize-observer";
import { PLAYING_BASESPEED } from "../../SimulationProvider";

interface CustomerProps {
  coordinates: CoordinatePoint;
  customerInstance: CustomerInstance | PickerInstance;
  simulationSpeed: number;
  entranceCoordinates: CoordinatePoint;
  handleCustomerSelection: (customerId: string) => void;
  selected: boolean;
}

export default function Customer({
  coordinates,
  customerInstance,
  simulationSpeed,
  entranceCoordinates,
  handleCustomerSelection,
  selected
}: CustomerProps) {
  const [gotAllItems, setGotAllItems] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [customerWidth, setCustomerWidth] = useState<number>(0);
  const [customerHeight, setCustomerHeight] = useState<number>(0);
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      scope.current,
      {
        x: coordinates.x - 0.5 * customerWidth,
        y: coordinates.y - 0.5 * customerHeight
      },
      {
        ease: "linear",
        duration: PLAYING_BASESPEED / simulationSpeed / 1000 // convert from ms to seconds
      }
    );
  }, [
    coordinates,
    customerHeight,
    customerWidth,
    scope,
    animate,
    simulationSpeed
  ]);

  // update customerSize
  useResizeObserver(scope, (entry) => {
    setCustomerHeight(entry.borderBoxSize[0].blockSize);
    setCustomerWidth(entry.borderBoxSize[0].inlineSize);
  });

  // check if customer got all items
  useEffect(() => {
    setGotAllItems(Object.keys(customerInstance.short_picks).length === 0);
  }, [customerInstance.short_picks]);
  // update progress of shopping list
  useEffect(() => {
    const nrOfNeededGoods = Object.keys(customerInstance.shopping_list).length;
    const shoppingProgress =
      (customerInstance.initial_length_of_shopping_list - nrOfNeededGoods) /
      customerInstance.initial_length_of_shopping_list;
    setProgress(shoppingProgress);
  }, [customerInstance]);

  const selectThisCustomer = () =>
    handleCustomerSelection(customerInstance.player_id);

  return (
    <motion.div
      ref={scope}
      className={styles["customerframe"]}
      id={customerInstance.player_id}
      style={{
        background: selected
          ? "var(--adagocolors-accent-1)"
          : "var(--Colors-Neutral-Border-colorBorder)",
        position: "absolute",
        border: gotAllItems
          ? "1.5px solid var(--Colors-Brand-Success-colorSuccess)"
          : "1.5px solid var(--Colors-Brand-Error-colorError)",
        ...entranceCoordinates
      }}
      onClick={selectThisCustomer}
    >
      <div className={styles["iconcontainer"]}>
        <div className={styles["iconframe"]}>
          {customerInstance.player_type === "customer" ? (
            <UserOutlined className={styles["icon"]} />
          ) : customerInstance.player_type === "picker" ? (
            <SettingOutlined className={styles["icon"]} />
          ) : null}
        </div>
      </div>
      <Progress
        percent={progress * 100}
        size="small"
        showInfo={false}
        className={styles["progressbar"]}
        strokeColor={"var(--Colors-Brand-Primary-colorPrimary"}
        success={{
          strokeColor: "var(--Colors-Brand-Primary-colorPrimary"
        }}
      />
    </motion.div>
  );
}
