import React, { useEffect, useState } from "react";
import { Check, X } from "react-bootstrap-icons";
import styles from "../../../../styles/SimulationEngine/Store/SideDrawer/CustomerInformation.module.css";
import { Radio, RadioChangeEvent, Typography } from "antd";
import { StatsCardSimple } from "./StatsCardsWoolworth";
import { MoneyCollectOutlined } from "@ant-design/icons";
import {
  CustomerStateMessage,
  GoodsStateMessage
} from "../../../../models/messageSpec";
import { SidebarContentType } from "../../../../types/SidebarTypes";
const { Text } = Typography;

interface CustomerInfoProps {
  sidebarObjectId: (string | number)[];
  customerStateMessage: CustomerStateMessage;
  goodsStateMessage: GoodsStateMessage;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
}

enum ShoppingListItemStatus {
  AVAILABLE,
  NOT_AVAILABLE,
  FUTURE
}
interface ShoppingListItem {
  id: string;
  displayedText: string;
  status: ShoppingListItemStatus;
  time: string;
}

export default function CustomerInformation(
  props: CustomerInfoProps
): React.ReactNode {
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [customerType, setCustomerType] = useState<string>("");
  const [shoppingList, setShoppingList] = useState<ShoppingListItem[]>([]);

  // write selection in Radio group back to variable
  const onChange = (e: RadioChangeEvent) => {
    setSelectedCustomer(e.target.value);
  };

  // set selected customer to first possible customer on new selection
  useEffect(() => {
    setSelectedCustomer(props.sidebarObjectId[0] as string);
  }, [props.sidebarObjectId]);

  // keep track of the Customer type
  useEffect(() => {
    if (
      props.customerStateMessage &&
      selectedCustomer &&
      props.customerStateMessage.customer_id_dict[selectedCustomer]
    ) {
      if (
        props.customerStateMessage.customer_id_dict[selectedCustomer]
          .player_type === "customer"
      ) {
        setCustomerType("Customer");
      } else if (
        props.customerStateMessage.customer_id_dict[selectedCustomer]
          .player_type === "picker"
      ) {
        setCustomerType("Picker");
      } else {
        setCustomerType("");
        console.warn("Unknown customer type");
      }
    } else {
      setCustomerType("");
    }
  }, [selectedCustomer, props.customerStateMessage]);

  // handle no data available situations
  useEffect(() => {
    if (props.customerStateMessage && selectedCustomer) {
      // check if customer is still in customer dict
      if (
        !Object.keys(props.customerStateMessage.customer_id_dict).includes(
          selectedCustomer
        )
      ) {
        // if selected customer is no longer in customer dict, reset everything and unselect customer
        setCustomerType("");
        setSelectedCustomer(null);
        setShoppingList([]);
        props.setSidebarContentType(SidebarContentType.NONE);
        props.setSidebarObjectId([]);
      }
    } else {
      // if there is no data (yet), set to default values
      setCustomerType("");
      setShoppingList([]);
    }
  }, [props, selectedCustomer]);

  // bring the shopping list into a format that can be displayed
  useEffect(() => {
    if (props.customerStateMessage && selectedCustomer) {
      // check that customer is still in customer dict
      if (
        Object.keys(props.customerStateMessage.customer_id_dict).includes(
          selectedCustomer
        )
      ) {
        const newShoppingList: ShoppingListItem[] = [];
        const shoppingCart =
          props.customerStateMessage.customer_id_dict[selectedCustomer]
            .shopping_cart;
        const shortPicks =
          props.customerStateMessage.customer_id_dict[selectedCustomer]
            .short_picks;
        // add items from the shopping cart if they are not a short pick
        Object.keys(shoppingCart).forEach((product: string) => {
          if (!shortPicks[product]) {
            const productName =
              props.goodsStateMessage.product_dict[product].product_name;
            newShoppingList.push({
              id: product,
              displayedText: `${shoppingCart[product]} pc ${productName}`,
              status: ShoppingListItemStatus.AVAILABLE,
              time: "15:05"
            });
          }
        });
        // add items from the shopping cart
        Object.keys(shortPicks).forEach((product: string) => {
          const productName =
            props.goodsStateMessage.product_dict[product].product_name;
          newShoppingList.push({
            id: product,
            displayedText: `${shortPicks[product]} pc ${productName}`,
            status: ShoppingListItemStatus.NOT_AVAILABLE,
            time: "15:00"
          });
        });
        // add items from the shopping list (i.e. items where pickup is still in the future)
        const rawShoppingList =
          props.customerStateMessage.customer_id_dict[selectedCustomer]
            .shopping_list;
        Object.keys(rawShoppingList).forEach((product: string) => {
          const productName =
            props.goodsStateMessage.product_dict[product].product_name;
          newShoppingList.push({
            id: product,
            displayedText: `${rawShoppingList[product]} pc ${productName}`,
            status: ShoppingListItemStatus.FUTURE,
            time: ""
          });
        });
        setShoppingList(newShoppingList);
      }
    }
  }, [props.customerStateMessage, props.goodsStateMessage, selectedCustomer]);

  // determine which status icon should be displayed depending on the list items status
  const getStatusIcon = (itemStatus: ShoppingListItemStatus) => {
    switch (itemStatus) {
      case ShoppingListItemStatus.AVAILABLE:
        return (
          <div className={styles["AvailableBox"]}>
            <Check className={styles["ShoppingListIcon"]} />
          </div>
        );
      case ShoppingListItemStatus.NOT_AVAILABLE:
        return (
          <div className={styles["NotAvailableBox"]}>
            <X className={styles["ShoppingListIcon"]} />
          </div>
        );
      case ShoppingListItemStatus.FUTURE:
        return <div className={styles["NotYetGrabbed"]} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {props.sidebarObjectId.length > 0 && (
        <div className={styles["Box"]}>
          <div className={styles["GeneralInfo"]}>
            <Text
              className={styles["Titles"]}
            >{`${customerType} Information`}</Text>
            {/* only display selection option if there is more than one customer to select from  */}
            {props.sidebarObjectId.length > 1 && (
              <Radio.Group
                onChange={onChange}
                className={styles["Radio"]}
                defaultValue={props.sidebarObjectId[0]}
                size="small"
                value={selectedCustomer}
              >
                {props.sidebarObjectId.map(
                  (customerId: string, index: number) => {
                    return (
                      <Radio.Button
                        key={customerId}
                        className={styles["RadioButton"]}
                        value={customerId}
                      >
                        {index + 1}
                      </Radio.Button>
                    );
                  }
                )}
              </Radio.Group>
            )}
            <div className={styles["StatsCardsFrame"]}>
              <StatsCardSimple
                icon={<MoneyCollectOutlined />}
                title="Customer ID"
                value={selectedCustomer}
              ></StatsCardSimple>
              <StatsCardSimple
                icon={<MoneyCollectOutlined />}
                title="Time spent in store"
                value={"xx min"}
              ></StatsCardSimple>
            </div>
            <Text className={styles["Titles"]}>Shopping List</Text>
            <div className={styles["StatsCardsFrame"]}>
              {shoppingList.map((shoppingListItem) => {
                return (
                  <div
                    key={shoppingListItem.id}
                    className={styles["ShoppingItemBox"]}
                  >
                    {getStatusIcon(shoppingListItem.status)}
                    <Text className={styles["Item"]}>
                      {shoppingListItem.displayedText}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
