export function onlyGetTime(rawTime: string): string {
  if (rawTime) {
    return rawTime.slice(11, -3).trim();
  } else {
    return "";
  }
}

export function onlyGetDate(rawTime: string): string {
  if (rawTime) {
    return rawTime.slice(0, 11).trim();
  } else {
    return "";
  }
}
