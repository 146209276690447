import { Typography } from "antd";
import React from "react";
import styles from "../../../../styles/SimulationEngine/Store/OfflineStore/GoodsNode.module.css";
import { SidebarContentType } from "../../../../types/SidebarTypes";
import { CoordinatePoint, Product } from "../../../../models/messageSpec";
import GoodsRing from "./GoodsRing";
const { Text } = Typography;

interface GoodsNodeProps {
  title: string;
  coordinates: CoordinatePoint;
  product: Product;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  selected: boolean;
  onlineCart: number;
  offlineCart: number;
}
export default function GoodsNode({
  title,
  coordinates,
  product,
  setSidebarContentType,
  setSidebarObjectId,
  selected,
  onlineCart,
  offlineCart
}: GoodsNodeProps) {
  // handle click on product
  const handleGoodSelection = () => {
    setSidebarContentType(SidebarContentType.GOOD);
    setSidebarObjectId([product.product_id]);
  };

  return (
    <div
      className={styles["frame"]}
      style={{
        position: "absolute",
        top: coordinates.y - 25, // center of circle is at coordinate (-radius)
        left: coordinates.x - 25
      }}
      onClick={handleGoodSelection}
    >
      <div className={styles["circle-container"]}>
        <div className={styles["text-container"]}>
          <div className={styles["text-container2"]}>
            <Text className={styles["ring-text"]}>{`${Math.round(
              product.stock
            )} pc`}</Text>
          </div>
        </div>
        <div className={styles["ring"]}>
          <GoodsRing
            stock={product.stock}
            online={onlineCart}
            cart={offlineCart}
          />
        </div>
      </div>
      <div
        className={styles["item-name"]}
        style={{
          background: selected
            ? "var(--adagocolors-accent-1)"
            : "var(--Colors-Neutral-Border-colorBorder)"
        }}
      >
        <div className={styles["item-text-div"]}>
          <p className={styles["item-text"]}>{title}</p>
        </div>
      </div>
    </div>
  );
}
