import React, { useEffect, useState } from "react";
import styles from "../../../../styles/SimulationEngine/Store/SideDrawer/GoodsAndGeneralInfo.module.css";
import { Progress, Typography } from "antd";
import {
  AtgStateMessage,
  GoodsStateMessage,
  StorageStateMessage
} from "../../../../models/messageSpec";
import { SidebarContentType } from "../../../../types/SidebarTypes";
import { StatsCardArrow } from "../../../Common/StatsCards";
import { Bookshelf, Boxes, GraphUpArrow, Laptop } from "react-bootstrap-icons";
import DateLinePlot, {
  DatePlotData,
  DatePoint
} from "../../../Common/DatePlotGraphic";
import { StatsCardSimple } from "./StatsCardsWoolworth";

const { Text } = Typography;

interface GoodsInformationProps {
  goodsStateMessages: GoodsStateMessage[];
  atgStateMessages: AtgStateMessage[];
  storageStateMessages: StorageStateMessage[];
  currentGoodsStateMessage: GoodsStateMessage;
  currentAtgMessage: AtgStateMessage;
  currentStorageMessaage: StorageStateMessage;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  sidebarObjectId: (string | number)[];
  cycleTimeLookup: { [key: number]: string };
}

interface ProductStats {
  name: string;
  shelfStock: number;
  storageStock: number;
  onlineStock: number;
  onlineActualDiff: number;
  optimalOnlineStock: number;
  optimalOnlineDiff: number;
  successfulPicks: number;
  unsuccessfulPicks: number;
  successfulPicksPercentage: number;
}

const initProductStats: ProductStats = {
  name: "",
  shelfStock: 0,
  storageStock: 0,
  onlineStock: 0,
  onlineActualDiff: 0,
  optimalOnlineStock: 0,
  optimalOnlineDiff: -0.1,
  successfulPicks: 0,
  unsuccessfulPicks: 0,
  successfulPicksPercentage: 100
};

export default function GoodsInformation(
  props: GoodsInformationProps
): React.ReactNode {
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [productStats, setProductStats] =
    useState<ProductStats>(initProductStats);
  const [plotData, setPlotData] = useState<DatePlotData[]>([]);

  // keep selected product up to date
  useEffect(() => {
    if (props.sidebarObjectId.length > 0) {
      setSelectedProduct(props.sidebarObjectId[0] as string);
    } else {
      setSelectedProduct(null);
    }
  }, [props.sidebarObjectId]);

  // calculate product stats
  useEffect(() => {
    if (selectedProduct && props.currentGoodsStateMessage) {
      const productInstance =
        props.currentGoodsStateMessage.product_dict[selectedProduct];
      const shelfStock = productInstance.stock;
      const storageStock =
        props.currentStorageMessaage.inventory[selectedProduct];
      const onlineStock =
        props.currentAtgMessage.displayed_inventory[selectedProduct];
      const optimalOnlineDiff = -0.1;
      const calulatedStats: ProductStats = {
        name: productInstance.product_name,
        shelfStock: shelfStock,
        storageStock: storageStock,
        onlineStock: onlineStock,
        onlineActualDiff: onlineStock / (shelfStock + storageStock) - 1,
        //
        optimalOnlineStock:
          shelfStock +
          storageStock +
          (shelfStock + storageStock) * optimalOnlineDiff,
        optimalOnlineDiff: optimalOnlineDiff,
        successfulPicks: productInstance.successfull_picks,
        unsuccessfulPicks: productInstance.short_picks,
        successfulPicksPercentage:
          productInstance.short_picks === 0
            ? 100
            : Math.round(
                (productInstance.successfull_picks /
                  (productInstance.successfull_picks +
                    productInstance.short_picks)) *
                  100
              )
      };
      setProductStats(calulatedStats);
    } else {
      setProductStats(initProductStats);
    }
  }, [
    selectedProduct,
    props.currentGoodsStateMessage,
    props.currentAtgMessage.displayed_inventory,
    props.currentStorageMessaage.inventory
  ]);

  // calculate and update plot data
  useEffect(() => {
    if (
      selectedProduct &&
      props.currentGoodsStateMessage &&
      props.currentStorageMessaage
    ) {
      const shelfDataset: DatePoint[] = props.goodsStateMessages
        // only use previous data
        .filter((message) => {
          return message.cycle <= props.currentGoodsStateMessage.cycle;
        })
        // take the stock level
        .map((message) => {
          return {
            x: props.cycleTimeLookup[message.cycle],
            y: message.product_dict[selectedProduct].stock
          };
        });
      const storageDataset: DatePoint[] = props.storageStateMessages
        // only use previous data
        .filter((message) => {
          return message.cycle <= props.currentStorageMessaage.cycle;
        })
        // take the stock level
        .map((message) => {
          return {
            x: props.cycleTimeLookup[message.cycle],
            y: message.inventory[selectedProduct]
          };
        });
      // add storage and shelf
      const inventoryDataset = storageDataset.map((point, index) => {
        return { x: point.x, y: shelfDataset[index].y + point.y };
      });

      const onlineDataset: DatePoint[] = props.atgStateMessages
        // only use previous data
        .filter((message) => {
          return message.cycle <= props.currentAtgMessage.cycle;
        })
        // take the stock level
        .map((message) => {
          return {
            x: props.cycleTimeLookup[message.cycle],
            y: message.displayed_inventory[selectedProduct]
          };
        });
      const newPlotData: DatePlotData[] = [
        { label: "total Inventory", dataset: inventoryDataset },
        { label: "On Shelf", dataset: shelfDataset },
        { label: "displayed online", dataset: onlineDataset }
      ];
      setPlotData(newPlotData);
    } else {
      setPlotData([]);
    }
  }, [
    selectedProduct,
    props.currentGoodsStateMessage,
    props.currentStorageMessaage,
    props.goodsStateMessages,
    props.storageStateMessages,
    props.atgStateMessages,
    props.cycleTimeLookup,
    props.currentAtgMessage.cycle
  ]);

  return (
    <>
      <div className={styles["Box"]}>
        <div className={styles["GeneralInfo"]}>
          <Text
            className={styles["Titles"]}
          >{`Product Statistics for ${productStats.name}`}</Text>
          <div className={styles["StatsCardsFrame"]}>
            <div className={styles["PicksFrame"]}>
              <Text className={styles["StatisticTitle"]}>
                picks and no-picks (including offline customers)
              </Text>
              <div className={styles["PickNumbersBox"]}>
                <div>
                  <Text className={styles["PickNumbersText"]}>
                    {productStats.successfulPicks}
                  </Text>
                  <Text className={styles["PickPercentText"]}>
                    {`(${productStats.successfulPicksPercentage} %)`}
                  </Text>
                </div>
                <div>
                  <Text className={styles["PickNumbersText"]}>
                    {productStats.unsuccessfulPicks}
                  </Text>
                  <Text className={styles["PickPercentText"]}>{`(${
                    100 - productStats.successfulPicksPercentage
                  } %)`}</Text>
                </div>
              </div>
              <Progress
                percent={productStats.successfulPicksPercentage}
                strokeColor="var(--Colors-Brand-Success-colorSuccess)"
                trailColor="var(--Colors-Brand-Error-colorError)"
                showInfo={false}
              />
            </div>
          </div>
          <div className={styles["StatsCardsFrame"]}>
            <StatsCardSimple
              title="stock on shelf"
              value={`${Math.round(productStats.shelfStock * 100) / 100} pc`}
              icon={<Bookshelf />}
            />
            <StatsCardSimple
              title="stock in storage"
              value={`${Math.round(productStats.storageStock * 100) / 100} pc`}
              icon={<Boxes />}
            />
            <div className={styles["StatsCardsFrame"]}>
              <StatsCardArrow
                icon={<Laptop />}
                title="online displayed stock"
                value={`${productStats.onlineStock} pc`}
                arrowUp={productStats.onlineActualDiff > 0}
                arrowNumber={
                  Math.abs(
                    Math.round(productStats.onlineActualDiff * 100)
                  ).toString() + " %"
                }
                arrowText="compared to total stock"
              />
              <StatsCardArrow
                icon={<GraphUpArrow />}
                title="optimal online displayed stock"
                // value={`${
                //   Math.round(productStats.optimalOnlineStock * 100) / 100
                // } pc`}
                value="xx pc"
                arrowUp={productStats.optimalOnlineDiff > 0}
                arrowNumber={
                  "x %"
                  // Math.abs(
                  //   Math.round(productStats.optimalOnlineDiff * 100)
                  // ).toString() + " %"
                }
                arrowText="compared to actual stock"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["Box"]}>
        <div className={styles["GeneralInfo"]}>
          <Text
            className={styles["Titles"]}
          >{`Inventory of ${productStats.name}`}</Text>
          <DateLinePlot plotData={plotData} unit="pc" />
        </div>
      </div>
    </>
  );
}
