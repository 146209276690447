import React from "react";
import LandingPageCard from "./LandingPageCard";
import { Image, Typography } from "antd";
import landingPageGraphic from "../../assets/images/landing_page_pic.png";
import longLogo from "../../assets/logos/adago_Logo_positiv_cmyk.png";
import styles from "../../styles/LandingPage/LandingPage.module.css";
import { BoxPlotOutlined, DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Text } = Typography;

export default function LandingPage(): JSX.Element {
  return (
    <div className={styles["FullBox"]}>
      <div className={styles["UpperBox"]}>
        <div className={styles["TextBox"]}>
          <Text className={styles["CatchySlogan"]}>
            Revolutionizing supply chains with AI-powered solutions
          </Text>
          <Text className={styles["Subtitle"]}>
            Driving efficiency through simulation, optimization, and automation
          </Text>
        </div>
        <Image src={landingPageGraphic} width="40%" />
      </div>
      <div className={styles["LowerBox"]}>
        <Link to="/overview">
          <LandingPageCard
            icon={<DashboardOutlined />}
            title="Overview"
            description="See some KPIs about Woolworth stores."
          />
        </Link>
        <Link to="/simulation">
          <LandingPageCard
            icon={<BoxPlotOutlined />}
            title="Simulator"
            description="See how goods and customers move around in a store."
          />
        </Link>
      </div>
      <Image src={longLogo} height="70px" />
    </div>
  );
}
