import React, { useState } from "react";
import styles from "../../styles/AppShell/TopBar.module.css";
import { List, Search } from "react-bootstrap-icons";
import { Typography } from "antd";
import ProfileMenuDropdown from "./profileMenu";
import logoImage from "../../assets/logos/logo.png";
import { Link } from "react-router-dom";

const { Text } = Typography;

interface TopBarProps {
  setMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  menuCollapsed: boolean;
  siderWidthCollapsed: number;
}

export function Topbar({
  setMenuCollapsed,
  menuCollapsed,
  siderWidthCollapsed
}: TopBarProps): React.ReactNode {
  const iconNeutralColor = "var(--Colors-Neutral-Icon-colorIcon)";
  const iconHoverColor = "var(--Colors-Neutral-Icon-colorIconHover)";
  const [menuIconColor, setMenuIconColor] = useState<string>(iconNeutralColor);

  const onMenuIconEnter = () => setMenuIconColor(iconHoverColor);
  const onMenuIconExit = () => setMenuIconColor(iconNeutralColor);
  const handleMenuToggle = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <div className={styles["TopBar"]}>
      <div className={styles["MenuIconLogoFrame"]}>
        <div
          className={styles["MenuIconDiv"]}
          style={{
            width: siderWidthCollapsed
          }}
        >
          <List
            className={styles["IconTopbar"]}
            onClick={handleMenuToggle}
            onMouseEnter={onMenuIconEnter}
            onMouseLeave={onMenuIconExit}
            color={menuIconColor}
          />
        </div>
        <Link to="/home">
          <div className={styles["LogoIntellitwinFrame"]}>
            <img src={logoImage} height={28} alt="Company Logo" />
            <Text className={styles["Intellitwin"]}>Woolworth Retail Twin</Text>
          </div>
        </Link>
      </div>
      <div className={styles["MenuBoxRight"]}>
        <div className={styles["SearchBar"]}>
          <div className={styles["InputBasic"]}>
            <div className={styles["ContentSearchBar"]}>
              <Text className={styles["Searchfunction"]}>Search</Text>
              <Search className={styles["SearchIcon"]}></Search>
            </div>
          </div>
        </div>
        <ProfileMenuDropdown />
      </div>
    </div>
  );
}
