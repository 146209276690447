import React, { useState } from "react";
import styles from "../../../styles/SimulationEngine/Store/SimSettingsAlertBox.module.css";
import { Button, DatePicker, Modal, Select } from "antd";

import { Typography } from "antd";
import { StoreInfo } from "../../../types/SimulationSharedProps";

const { Text } = Typography;

interface SimSettingsAlertBoxProps {
  onClose: () => void;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  selectedStore: StoreInfo;
  setSelectedStore: React.Dispatch<React.SetStateAction<StoreInfo>>;
  availableStores: StoreInfo[];
  sendStartSimulationMessage: () => void;
  open: boolean;
}

export function SimSettingssAlertBox({
  onClose,
  setStartDate,
  selectedStore,
  startDate,
  setSelectedStore,
  availableStores,
  sendStartSimulationMessage,
  open
}: SimSettingsAlertBoxProps): React.ReactNode {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const shouldSubmitBeDisabled = () => {
    if (selectedStore && submitDisabled) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  return (
    <Modal
      title="Please enter a start date to begin the simulation."
      open={open}
      footer={[
        <Button
          key="startSimulation"
          type="primary"
          disabled={submitDisabled}
          onClick={() => {
            sendStartSimulationMessage();
            onClose();
          }}
        >
          start Simulation
        </Button>
      ]}
      onCancel={onClose}
    >
      <div className={styles["TextWrapper"]}>
        <Text className={styles["Text"]}>
          You haven&apos;t provided the simulation properties yet, or you can
          update them if needed. Please enter your desired start time and store
          to begin the simulation.
        </Text>
        <div className={styles["DatePickerBox"]}>
          <Select
            showSearch
            value={selectedStore ? selectedStore.id : null}
            className={styles["DatePicker"]}
            placeholder="Select Store"
            options={availableStores.map((storeInfo) => {
              return {
                key: storeInfo.id,
                value: storeInfo.id,
                label: `${storeInfo.id} - ${storeInfo.name}`
              };
            })}
            onChange={(value) => {
              setSelectedStore(
                availableStores.find((storeInfo) => storeInfo.id === value)
              );
              shouldSubmitBeDisabled();
            }}
          />
          <DatePicker
            className={styles["DatePicker"]}
            placeholder="Start date"
            showTime={{ format: "HH:mm" }}
            disabledTime={(date) => {
              return {
                disabledHours: () =>
                  Array.from(Array(24).keys()).filter((h) => {
                    if (selectedStore) {
                      return (
                        h <= selectedStore.opening || h >= selectedStore.closing
                      );
                    } else {
                      return [];
                    }
                  })
              };
            }}
            onChange={(date, dateString) => {
              if (typeof dateString === "string") {
                setStartDate(dateString);
                shouldSubmitBeDisabled();
              }
            }}
          ></DatePicker>
        </div>
      </div>
    </Modal>
  );
}
