import React, { useState } from "react";
import { Typography } from "antd";
import styles from "../../styles/LandingPage/LandingPageCard.module.css";

const { Text } = Typography;

interface LandingPageCardProps {
  icon: React.ReactElement; // bootstrap icon
  title: string;
  description: string;
}

export default function LandingPageCard(
  props: LandingPageCardProps
): JSX.Element {
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setHighlighted(true);
  };

  const handleMouseLeave = () => {
    setHighlighted(false);
  };

  const highlightStyle = {
    border:
      "var(--Size-Line-Width-lineWidth) solid var(--Colors-Brand-Primary-colorPrimaryBorderHover)"
  };

  return (
    <div
      className={styles["CardBox"]}
      style={highlighted ? highlightStyle : {}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles["IconBox"]}>
        {React.cloneElement(props.icon, {
          className: styles["Icon"]
        })}
      </div>
      <div className={styles["CardTextBox"]}>
        <Text className={styles["Title"]}>{props.title}</Text>
        <Text className={styles["Description"]}>{props.description}</Text>
      </div>
    </div>
  );
}
