import React, { useEffect, useState } from "react";
import {
  GoodsStateMessage,
  StoreStateMessage,
  CoordinatePoint,
  Product,
  OnlineCustomerStateMessage,
  CustomerStateMessage,
  PlayerType
} from "../../../../models/messageSpec";
import GoodsNode from "./GoodsNode";
import { SidebarContentType } from "../../../../types/SidebarTypes";

interface GoodsDisplayProps {
  goodsStateMessage: GoodsStateMessage;
  storeStateMessage: StoreStateMessage;
  onlineCustomerMessage: OnlineCustomerStateMessage;
  customerMessage: CustomerStateMessage;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  sidebarObjectId: (string | number)[];
  sidebarContentType: SidebarContentType;
  storeWidth: number;
  storeHeight: number;
}

export default function GoodsDisplayInStore({
  goodsStateMessage,
  storeStateMessage,
  customerMessage,
  onlineCustomerMessage,
  setSidebarContentType,
  setSidebarObjectId,
  sidebarObjectId,
  sidebarContentType,
  storeHeight,
  storeWidth
}: GoodsDisplayProps) {
  const [products, setProducts] = useState<{ [k: string]: Product }>({});
  const [coordinates, setCoordinates] = useState<{
    [k: string]: CoordinatePoint;
  }>({});
  const [stockInOnlineCart, setStockInOnlineCart] = useState<{
    [k: string]: number;
  }>({});
  const [stockInCart, setStockInCart] = useState<{ [k: string]: number }>({});

  // Update products and coordinates based on currentCycle
  useEffect(() => {
    if (goodsStateMessage && storeStateMessage) {
      setProducts(goodsStateMessage.product_dict);
      const newCoords = {};
      Object.keys(storeStateMessage.goods_coordinates_dict).forEach(
        (product_id) => {
          const unscaledCords =
            storeStateMessage.goods_coordinates_dict[product_id];
          newCoords[product_id] = {
            x: unscaledCords.x * storeWidth,
            y: unscaledCords.y * storeHeight
          };
        }
      );
      setCoordinates(newCoords);
    } else {
      setProducts({});
      setCoordinates({});
    }
  }, [goodsStateMessage, storeStateMessage, storeHeight, storeWidth]);

  // calculate stock in online cart
  useEffect(() => {
    if (goodsStateMessage && onlineCustomerMessage && customerMessage) {
      const onlineCartsSums: { [k: string]: number } = {};
      Object.keys(goodsStateMessage.product_dict).forEach((productId) => {
        // look at each customer that has not yet a picker assigned and add the amounts of the product on their shopping list
        let sumOnlineCart = 0;
        if (
          onlineCustomerMessage.waiting_for_fullfillment &&
          onlineCustomerMessage.waiting_for_fullfillment.length > 0
        ) {
          onlineCustomerMessage.waiting_for_fullfillment.forEach(
            (onlineCustomerId) => {
              const onlineShoppingList =
                onlineCustomerMessage.online_customer_dict[onlineCustomerId]
                  .shopping_list;
              if (onlineShoppingList[productId]) {
                sumOnlineCart += onlineShoppingList[productId];
              }
            }
          );
        }
        // also look at the pickers that have not yet reached the item
        Object.values(customerMessage.customer_id_dict).forEach(
          (customerInstance) => {
            if (
              customerInstance.player_type === PlayerType.picker &&
              customerInstance.shopping_list[productId]
            ) {
              sumOnlineCart += customerInstance.shopping_list[productId];
            }
          }
        );

        // save the result
        onlineCartsSums[productId] = sumOnlineCart;
      });

      setStockInOnlineCart(onlineCartsSums);
    }
  }, [customerMessage, onlineCustomerMessage, goodsStateMessage]);

  // calculate stock in cart
  useEffect(() => {
    if (goodsStateMessage && customerMessage) {
      const goodsInCarts: { [k: string]: number } = {};
      Object.keys(goodsStateMessage.product_dict).forEach((productId) => {
        let sumInCart = 0;
        // look at all customer sand pickers and add the goods in their carts
        Object.values(customerMessage.customer_id_dict).forEach(
          (customerInstance) => {
            if (customerInstance.shopping_cart[productId]) {
              sumInCart += customerInstance.shopping_cart[productId];
            }
          }
        );
        goodsInCarts[productId] = sumInCart;
      });
      setStockInCart(goodsInCarts);
    }
  }, [goodsStateMessage, customerMessage]);

  return (
    <>
      {Object.keys(products).map((product_id) => {
        const product = products[product_id];
        const productCoordinates: CoordinatePoint = coordinates[product_id];

        return (
          <GoodsNode
            key={product_id}
            title={product.product_name}
            coordinates={productCoordinates}
            product={product}
            setSidebarContentType={setSidebarContentType}
            setSidebarObjectId={setSidebarObjectId}
            selected={
              sidebarObjectId.includes(product_id) &&
              sidebarContentType === SidebarContentType.GOOD
            }
            onlineCart={stockInOnlineCart[product_id]}
            offlineCart={stockInCart[product_id]}
          />
        );
      })}
    </>
  );
}
