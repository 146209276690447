import React, { useEffect, useState } from "react";
import styles from "../../../../styles/SimulationEngine/Store/SideDrawer/SidebarWoolworth.module.css";
import GeneralInformation from "./GeneralInformation";
import GoodsInformation from "./GoodsInfo";
import CustomerInformation from "./CustomerInformation";
import {
  StoreStateMessage,
  CustomerStateMessage,
  GoodsStateMessage,
  OnlineCustomerStateMessage,
  AtgStateMessage,
  StorageStateMessage
} from "../../../../models/messageSpec";
import { getMessage } from "../../../../utils/messageUtils";
import { SidebarContentType } from "../../../../types/SidebarTypes";
import { StoreInfo } from "../../../../types/SimulationSharedProps";

interface SidebarProps {
  storeStateMessages: StoreStateMessage[];
  customerStateMessages: CustomerStateMessage[];
  goodsStateMessages: GoodsStateMessage[];
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  atgStateMessages: AtgStateMessage[];
  storageStateMessages: StorageStateMessage[];
  currentCycle: number;
  sidebarContentType: SidebarContentType;
  sidebarObjectId: (string | number)[];
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  cycleTimeLookup: { [key: number]: string };
  selectedStore: StoreInfo;
}

interface CurrentMessages {
  storeStateMessage: StoreStateMessage;
  customerStateMessage: CustomerStateMessage;
  goodsStateMessage: GoodsStateMessage;
  onlineCustomerStateMessage: OnlineCustomerStateMessage;
  atgMessage: AtgStateMessage;
  storageStateMessage: StorageStateMessage;
}

export function SidebarWoolworth(props: SidebarProps): React.ReactNode {
  const [currentMessages, setCurrentMessages] = useState<CurrentMessages>();

  useEffect(() => {
    const newCurrentMessages: CurrentMessages = {
      storeStateMessage: getMessage(
        props.currentCycle,
        props.storeStateMessages
      ) as StoreStateMessage,
      customerStateMessage: getMessage(
        props.currentCycle,
        props.customerStateMessages
      ) as CustomerStateMessage,
      goodsStateMessage: getMessage(
        props.currentCycle,
        props.goodsStateMessages
      ) as GoodsStateMessage,
      onlineCustomerStateMessage: getMessage(
        props.currentCycle,
        props.onlineCustomerMessages
      ) as OnlineCustomerStateMessage,
      atgMessage: getMessage(
        props.currentCycle,
        props.atgStateMessages
      ) as AtgStateMessage,
      storageStateMessage: getMessage(
        props.currentCycle,
        props.storageStateMessages
      ) as StorageStateMessage
    };
    setCurrentMessages(newCurrentMessages);
  }, [props]);

  return (
    <div className={styles["Sidebar"]}>
      <GeneralInformation {...currentMessages} {...props} />
      {props.sidebarContentType === SidebarContentType.GOOD && (
        <GoodsInformation
          {...props}
          currentGoodsStateMessage={currentMessages.goodsStateMessage}
          currentAtgMessage={currentMessages.atgMessage}
          currentStorageMessaage={currentMessages.storageStateMessage}
        />
      )}
      {props.sidebarContentType === SidebarContentType.CUSTOMER && (
        <CustomerInformation {...props} {...currentMessages} />
      )}
    </div>
  );
}
