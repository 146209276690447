import React, { useRef, useState } from "react";
import styles from "../../styles/SimulationEngine/Store/MainVisualization.module.css";
import { SidebarWoolworth } from "./Store/SideDrawer/SidebarWoolworth";
import { LegendWoolworth } from "./Store/LegendWoolworth";
import { Layout, Spin } from "antd";
import Store from "./Store/Store";
import SimulationSharedProps from "../../types/SimulationSharedProps";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import useResizeObserver from "@react-hook/resize-observer";
import { TimeSlider } from "./Store/Timeslider";
import { ChangeSimulationSettings } from "./Store/ChangeSettingsButton";
import { SimSettingssAlertBox } from "./Store/SimSettingsAlertBox";

interface MainVisualizationProps {
  props: SimulationSharedProps;
}

export default function MainVisualization({ props }: MainVisualizationProps) {
  const [displayStartDateAlertShowing, setDisplayStartDateAlertShowing] =
    useState(true);
  const [storeHeight, setStoreHeight] = useState(0);
  const layoutRef = useRef<HTMLDivElement>();
  useResizeObserver(layoutRef, (entry) => {
    setStoreHeight(entry.contentRect.height);
  });

  const onChangeDateClick = () => {
    setDisplayStartDateAlertShowing(true);
  };

  const onAlertClose = () => {
    setDisplayStartDateAlertShowing(false);
  };

  return (
    <Layout>
      <Header className={styles["LegendHeader"]}>
        <LegendWoolworth />
      </Header>
      <Layout ref={layoutRef} className={styles["SecondLayout"]}>
        <Spin spinning={props.waitingForData} fullscreen></Spin>
        <Content>
          <div className={styles["Content"]}>
            <SimSettingssAlertBox
              onClose={onAlertClose}
              {...props}
              open={displayStartDateAlertShowing}
            />
            <Store {...props} />
            <div className={styles["NavigationContainer"]}>
              <div className={styles["ChangeDateContainer"]}>
                <ChangeSimulationSettings onClick={onChangeDateClick} />
              </div>
              <TimeSlider {...props} />
            </div>
          </div>
        </Content>
        <Sider
          width={324}
          theme="light"
          className={styles["LayoutSider"]}
          style={{ height: storeHeight }}
        >
          <SidebarWoolworth {...props} />
        </Sider>
      </Layout>
    </Layout>
  );
}
