import React from "react";
import styles from "../../../styles/SimulationEngine/Store/ChangeSettingsButton.module.css";
import { GearFill } from "react-bootstrap-icons";

interface ChangeDateProps {
  onClick: () => void;
}

export function ChangeSimulationSettings({
  onClick
}: ChangeDateProps): React.ReactNode {
  return (
    <div className={styles["ChangeDateBox"]} onClick={onClick}>
      <GearFill className={styles["ChangeDateIcon"]} />
    </div>
  );
}
