/**
 * Message sent by WebSocketObject to update the frontend about all object states in simulation
 */
export interface Message {
  message_type: string;
}

export interface InitAnswer extends Message {
  store_info: {
    [k: string]: { store_name: string; Opening: number; Closing: number };
  };
}

export type SimulationMessage =
  | GoodsStateMessage
  | CustomerStateMessage
  | StoreStateMessage
  | OnlineCustomerStateMessage
  | AtgStateMessage
  | StorageStateMessage;

export interface GoodsStateMessage extends Message {
  current_time: string;
  cycle: number;
  object_id: string;
  product_dict: {
    [k: string]: Product;
  };
}
export interface Product {
  product_id: string;
  product_name: string;
  stock: number;
  short_picks: number;
  successfull_picks: number;
  max_stock?: number;
}
export interface CustomerStateMessage extends Message {
  current_time: string;
  cycle: number;
  object_id: string;
  customer_id_dict: {
    [k: string]: CustomerInstance | PickerInstance;
  };
  total_customer_count: number;
}
export interface CustomerInstance {
  counter: number;
  player_id: string;
  player_type: PlayerType;
  shopping_list: {
    [k: string]: number;
  };
  initial_length_of_shopping_list: number;
  short_picks: { [k: string]: number };
  shopping_cart: { [k: string]: number };
}

export interface PickerInstance {
  counter: number | null;
  player_id: string;
  player_type: PlayerType;
  shopping_cart: { [k: string]: number };
  initial_length_of_shopping_list: number;
  shopping_list: {
    [k: string]: number;
  };
  short_picks: { [k: string]: number };
  online_order_id: string;
}

export enum PlayerType {
  customer = "customer",
  picker = "picker",
  onlineCustomer = "online_customer"
}

export interface ShoppingCart {
  items: { [k: string]: number };
}
export interface StoreStateMessage extends Message {
  cycle: number;
  current_time: string;
  object_id: string;
  goods_coordinates_dict: {
    [k: string]: CoordinatePoint;
  };
  customer_coordinates: {
    [k: string]: CoordinatePoint;
  };
  route: CoordinatePoint[];
}
export interface CoordinatePoint {
  x: number;
  y: number;
}

export interface OnlineCustomerStateMessage extends Message {
  cycle: number;
  current_time: string;
  object_id: string;
  online_customer_dict: {
    [k: string]: OnlineCustomerInstance;
  };
  waiting_for_fullfillment: Array<string>;
  total_customer_count: number;
}

export interface OnlineCustomerInstance {
  player_id: string;
  player_type: string;
  shopping_cart: { [k: string]: number };
  shopping_list: {
    [k: string]: number;
  };
  short_picks: Array<string>;
  counter: number;
  delivery_type: string;
}

export interface AtgStateMessage extends Message {
  current_time: string;
  cycle: number;
  displayed_inventory: { [k: number]: number };
  online_inventory: { [k: number]: number };
  online_inventory_decrements: number[];
}

export interface StorageStateMessage extends Message {
  current_time: string;
  cycle: number;
  inventory: { [k: string]: number };
}
