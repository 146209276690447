import React from "react";
import comingSoonPicture from "../../assets/images/coming_soon_pic.png";
import styles from "../../styles/Common/ComingSoon.module.css";
import { Typography } from "antd";

const { Text } = Typography;

export default function ComingSoon(): React.ReactElement {
  return (
    <div className={styles["OuterBox"]}>
      <img
        src={comingSoonPicture}
        className={styles["Graphic"]}
        alt="Coming Soon"
      />
      <Text className={styles["ComingSoon"]}>COMING SOON</Text>
      <Text className={styles["ExplanationParagraph"]}>
        This page is currently under construction. We will be back soon!
      </Text>
    </div>
  );
}
