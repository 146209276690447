import React, { useEffect, useRef, useState } from "react";
import styles from "../../../styles/SimulationEngine/Store/Timeslider.module.css";
import { Slider, Typography } from "antd";
import { DatePicker } from "antd";
import {
  PauseOutlined,
  CaretRightOutlined,
  ForwardOutlined
} from "@ant-design/icons";
import { onlyGetDate, onlyGetTime } from "../../../utils/timeUtils";
import dayjs from "dayjs";

const { Text } = Typography;

interface SimulationSliderProps {
  setCurrentCycle: React.Dispatch<React.SetStateAction<number>>;
  simulationPaused: boolean;
  setSimulationPaused: React.Dispatch<React.SetStateAction<boolean>>;
  setSimulationSpeed: React.Dispatch<React.SetStateAction<number>>;
  simulationSpeed: number;
  cycleList: number[];
  cycleTimeLookup: { [k: number]: string };
  currentCycle: number;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const dateFormat = "YYYY-MM-DDT";

export function TimeSlider({
  setCurrentCycle, // Prop verwenden
  setSimulationSpeed,
  simulationSpeed,
  cycleList,
  cycleTimeLookup,
  currentCycle,
  isPlaying,
  setIsPlaying
}: SimulationSliderProps): React.ReactNode {
  // const HIDE_DELAY = 3000; // Time in milliseconds before the slider hides
  const sliderRefContainer = useRef<HTMLDivElement>();
  const [sliderValue, setSliderValue] = useState(cycleList.length - 1);
  const [minCycle, setMinCycle] = useState<number>(); // slider range
  const [maxCycle, setMaxCycle] = useState<number>();
  const [minDate, setMinDate] = useState<string>(); // date range for date pickre
  const [maxDate, setMaxDate] = useState<string>();
  const [currentDate, setCurrentDate] = useState<string>(); // current date (without time)

  const handleSpeedChange = () => {
    const newSpeed = simulationSpeed === 1 ? 2 : simulationSpeed === 2 ? 5 : 1; // Berechne die neue Geschwindigkeit
    setSimulationSpeed(newSpeed); // Update die Geschwindigkeit und gebe sie an MainVisualization zurück
  };

  const handleSliderChange = (value: number) => {
    setCurrentCycle(value); // Hier den aktuellen Zyklus setzen
  };

  // handle date picker changes
  const handleDateChange = (date, dateString) => {
    // change displayed date
    setCurrentDate(dateString);
    // change cycle to first on that day
    const cyclesOnDay = cycleList.filter((cycle) => {
      return onlyGetDate(cycleTimeLookup[cycle]) === dateString;
    });
    const selectedCycle = Math.min(...cyclesOnDay);
    setCurrentCycle(selectedCycle);
  };

  // let slider and date picker display the current cycle
  useEffect(() => {
    if (currentCycle !== null) {
      setSliderValue(currentCycle); // Setze den Sliderwert basierend auf currentCycle
      // set date picker value
      setCurrentDate(onlyGetDate(cycleTimeLookup[currentCycle]));
    }
  }, [currentCycle, cycleTimeLookup]);

  // update slider range and date picker range if cycle list changes
  useEffect(() => {
    if (cycleList.length > 0) {
      const cyclesCurrentDay = cycleList.filter((cycle) => {
        return onlyGetDate(cycleTimeLookup[cycle]) === currentDate;
      });
      setMinCycle(cyclesCurrentDay[0]);
      setMaxCycle(cyclesCurrentDay[cyclesCurrentDay.length - 1]);
    } else {
      setMinCycle(0);
      setMaxCycle(0);
    }
  }, [cycleList, currentDate, cycleTimeLookup]);

  // update date range
  useEffect(() => {
    const newMinDate = cycleTimeLookup[cycleList[0]];
    setMinDate(newMinDate);
    const newMaxDate = cycleTimeLookup[cycleList[cycleList.length - 1]];
    setMaxDate(newMaxDate);
  }, [cycleList, cycleTimeLookup]);

  return (
    <div className={styles["TimeSliderAll"]}>
      <div className={styles["BoxLeft"]}>
        <div className={styles["PlayPauseFrame"]}>
          {isPlaying ? (
            <PauseOutlined
              className={styles["PlayPauseIcon"]}
              onClick={() => setIsPlaying(false)}
            />
          ) : (
            <CaretRightOutlined
              className={styles["PlayPauseIcon"]}
              onClick={() => setIsPlaying(true)}
            />
          )}
          <ForwardOutlined
            className={styles["PlayPauseIcon"]}
            onClick={handleSpeedChange}
          />
          <Text
            className={styles["speedText"]}
            style={{ visibility: simulationSpeed === 1 ? "hidden" : "visible" }}
          >
            {simulationSpeed > 1 ? `${simulationSpeed}x` : "1x"}
          </Text>
        </div>
        <DatePicker
          className={styles["DateDisplayed"]}
          variant="borderless"
          value={dayjs(currentDate, dateFormat)}
          onChange={handleDateChange}
          minDate={dayjs(minDate, dateFormat)}
          maxDate={dayjs(maxDate, dateFormat)}
        ></DatePicker>
      </div>
      <div className={styles["BoxRight"]}>
        <Slider
          className={styles["date-slider"]}
          value={sliderValue}
          min={minCycle}
          max={maxCycle}
          onChange={handleSliderChange}
          marks={{
            [minCycle]: {
              label: onlyGetTime(cycleTimeLookup[minCycle]),
              style: {
                translate: "40%"
              }
            },
            [maxCycle]: {
              label: onlyGetTime(cycleTimeLookup[maxCycle]),
              style: {
                translate: "-40%"
              }
            }
          }}
          style={{ width: "100%", color: "#0D61FF" }}
          ref={sliderRefContainer}
          tooltip={{
            formatter: (value) => onlyGetTime(cycleTimeLookup[value])
          }}
        />
      </div>
    </div>
  );
}
