// src/msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";
import { appConfig } from "./config";
const msalConfig = {
  auth: {
    clientId: appConfig.clientId, // This is your client ID from Azure AD
    redirectUri: appConfig.redirectUri, // This is your redirect URI
    scopes: ["user.read"], // This is the scope you want to request
    authority: "https://login.microsoftonline.com/adago.de"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const msalInstance = new PublicClientApplication(msalConfig);
export { msalConfig, msalInstance };
