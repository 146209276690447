import React from "react";
import { Person } from "react-bootstrap-icons";
import styles from "../../../../styles/SimulationEngine/Store/OnlineStore/OnlineNodes.module.css";
// import "./Timeslider.module.css";
import { Typography } from "antd";
import { OnlineCustomerStateMessage } from "../../../../models/messageSpec";

const { Text } = Typography;

interface OnlineNodesProps {
  icon: React.ReactNode; // Für den Smiley oder jedes andere Icon
  title: string; // Für den Titel
  value: number | string; // Für die Zahlenanzeige
  style: React.CSSProperties;
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  currentCycle: number; //
}

export function OnlineNodes({
  icon = <Person className="iconSmiley" />, // Standardicon, wenn kein anderes übergeben wird
  title,
  value,
  style,
  onlineCustomerMessages,
  currentCycle
}: OnlineNodesProps): React.ReactNode {
  return (
    <div className={styles["OuterFrame"]}>
      <div className={styles["LeftFrame"]} style={style}>
        <div className={styles["ContentFrameLeft"]}>
          <div className={styles["IconFrame"]}>
            {React.cloneElement(icon as React.ReactElement, {
              className: styles["Icon"]
            })}
          </div>
          {/* <Text className={styles["Number"]}></Text> */}
        </div>
      </div>

      <div className={styles["RightFrame"]}>
        <Text className={styles["Title"]}>{title}</Text>
      </div>
    </div>
  );
}
