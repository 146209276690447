import React from "react";
import styles from "../../../../styles/SimulationEngine/Store/OfflineStore/ExitEntrance.module.css";
import { Typography } from "antd";
import { CustomerStateMessage } from "../../../../models/messageSpec";
import { SidebarContentType } from "../../../../types/SidebarTypes";
const { Text } = Typography;

interface ExitProps {
  currentCycle: number;
  customerStateMessages: CustomerStateMessage[];
  atCheckout: string[];
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
}

export default function Exit(props: ExitProps): React.ReactNode {
  const onClick = () => {
    props.setSidebarContentType(SidebarContentType.CUSTOMER);
    props.setSidebarObjectId(props.atCheckout);
  };
  return (
    <div
      className={styles["ExitFrame"]}
      onClick={onClick}
      style={{ cursor: props.atCheckout.length > 0 ? "pointer" : "auto" }}
    >
      <div className={styles["ContentFrameLeft"]}>
        <div className={styles["IconFrame"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            viewBox="0 0 512 512"
            className={styles["Icon"]}
          >
            <path d="M503.467 366.933H493.5l-41.378-223.42a8.526 8.526 0 0 0-8.388-6.98H409.6V76.8h34.133a8.53 8.53 0 0 0 8.533-8.533V8.533A8.53 8.53 0 0 0 443.733 0H307.2a8.53 8.53 0 0 0-8.533 8.533v59.733a8.53 8.53 0 0 0 8.533 8.533h34.133v59.733H196.267v-17.067a8.53 8.53 0 0 0-8.533-8.533H128a8.53 8.53 0 0 0-8.533 8.533v17.067h-51.2a8.526 8.526 0 0 0-8.388 6.98L18.5 366.933H8.533A8.53 8.53 0 0 0 0 375.466v128A8.53 8.53 0 0 0 8.533 512h494.933a8.53 8.53 0 0 0 8.533-8.533v-128a8.53 8.53 0 0 0-8.532-8.534zm-187.734-307.2V17.067H435.2v42.667H315.733zm76.8 17.067v59.733H358.4V76.8h34.133zm-256 51.2H179.2v93.867h-42.667V128zM128 238.933h59.733a8.53 8.53 0 0 0 8.533-8.533v-8.533h17.067V256H102.4v-34.133h17.067v8.533a8.53 8.53 0 0 0 8.533 8.533zM75.366 153.6h44.1v51.2h-25.6a8.53 8.53 0 0 0-8.533 8.533v51.2a8.53 8.53 0 0 0 8.533 8.533h128a8.53 8.53 0 0 0 8.533-8.533v-51.2a8.53 8.53 0 0 0-8.533-8.533h-25.6v-51.2h240.367l39.509 213.333H35.857L75.366 153.6zm419.567 341.333H17.067V384h477.867v110.933z" />
            <path d="M409.6 170.667H256a8.53 8.53 0 0 0-8.533 8.533v59.733a8.53 8.53 0 0 0 8.533 8.533h153.6a8.53 8.53 0 0 0 8.533-8.533V179.2a8.53 8.53 0 0 0-8.533-8.533zm-8.533 59.733H264.533v-42.667h136.533V230.4zM409.6 264.533h-17.067a8.53 8.53 0 0 0-8.533 8.533 8.53 8.53 0 0 0 8.533 8.533H409.6a8.53 8.53 0 0 0 8.533-8.533 8.53 8.53 0 0 0-8.533-8.533zM341.333 264.533h-17.067a8.53 8.53 0 0 0-8.533 8.533 8.53 8.53 0 0 0 8.533 8.533h17.067a8.53 8.53 0 0 0 8.533-8.533 8.53 8.53 0 0 0-8.533-8.533zM273.067 264.533H256a8.53 8.53 0 0 0-8.533 8.533 8.53 8.53 0 0 0 8.533 8.533h17.067a8.53 8.53 0 0 0 8.533-8.533 8.53 8.53 0 0 0-8.533-8.533zM409.6 298.667h-17.067c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533H409.6c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM341.333 298.667h-17.067c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533h17.067c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM273.067 298.667H256c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533h17.067c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM409.6 332.8h-17.067c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533H409.6c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM341.333 332.8h-17.067c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533h17.067c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM273.067 332.8H256c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533h17.067c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533z" />
          </svg>
        </div>
        <Text className={styles["Number"]}>{props.atCheckout.length}</Text>
      </div>
    </div>
  );
}
