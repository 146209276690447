import React, { useEffect, useState } from "react";
import styles from "../../../../styles/SimulationEngine/Store/OfflineStore/ExitEntrance.module.css";
import { Typography } from "antd";
import {
  CustomerStateMessage,
  OnlineCustomerStateMessage
} from "../../../../models/messageSpec";
import { getMessage } from "../../../../utils/messageUtils";

const { Text } = Typography;

interface EntranceProps {
  customerStateMessages: CustomerStateMessage[];
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  currentCycle: number;
}

export default function Entrance(props: EntranceProps): React.ReactNode {
  const [numberTotalCustomers, setNumberTotalCustomers] = useState<number>(0);

  useEffect(() => {
    if (!props.currentCycle) {
      setNumberTotalCustomers(0);
    } else {
      let customerCount = 0;
      // count online customers
      const ocMessage = getMessage(
        props.currentCycle,
        props.onlineCustomerMessages
      ) as OnlineCustomerStateMessage;
      if (ocMessage) {
        customerCount += ocMessage.total_customer_count;
      }
      // count offline customers
      const cMessage = getMessage(
        props.currentCycle,
        props.customerStateMessages
      ) as CustomerStateMessage;
      if (cMessage) {
        customerCount += cMessage.total_customer_count;
      }
      // keep track of sum of customers
      setNumberTotalCustomers(customerCount);
    }
  }, [props]);

  return (
    <div className={styles["OuterFrame"]}>
      <div className={styles["LeftFrameEntrance"]}>
        <Text className={styles["EntranceNumber"]}>{numberTotalCustomers}</Text>
      </div>
      <div className={styles["RightFrame"]}>
        <Text className={styles["Title"]}>total Customers</Text>
      </div>
    </div>
  );
}
