import { SimulationMessage } from "../models/messageSpec";

export function getMessage(
  currentCycle: number,
  messageList: SimulationMessage[]
): SimulationMessage {
  if (!currentCycle || !messageList || messageList.length === 0) {
    return null;
  }
  if (
    messageList[currentCycle - 1] &&
    messageList[currentCycle - 1].cycle === currentCycle
  ) {
    return messageList[currentCycle - 1];
  } else {
    const rightMessage = messageList.find(
      (message) => message.cycle === currentCycle
    );
    return rightMessage;
  }
}
