import React, { ReactNode } from "react";
import { ConfigProvider } from "antd";

// color definitions
const primaryColor = "#0D61FF";
const primaryBg = "#FFFFFF";
const bgBase = "#FFFFFF";
export function OurConfigProvider({ children }): ReactNode {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primaryColor,

          borderRadius: 6
        },
        components: {
          Layout: {
            //siderBg: primaryBg,
            lightSiderBg: primaryBg,
            //colorBorder: accent2,
            headerBg: primaryBg,
            //headerColor: text,
            bodyBg: bgBase,
            footerBg: bgBase
            //triggerBg: accent2
            //triggerColor: primaryBg,
            //lightTriggerColor: primaryBg,
            //controlItemBgActive: primaryBg,
            //controlItemBgActiveHover: primaryBg // Optional: Farbe beim Hover
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
}
