import React from "react";
import styles from "../../../styles/SimulationEngine/Store/LegendWoolworth.module.css";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Typography } from "antd";
const { Text } = Typography;

export function LegendWoolworth(): React.ReactNode {
  return (
    <div className={styles["LegendBox"]}>
      <div className={styles["CategoryLegendBox"]}>
        <Text className={styles["CategoryTitle"]}>Node Status</Text>
        <div className={styles["FrameCategory"]}>
          <div className={styles["ItemFrame"]}>
            <div className={styles["NodeStatusRed"]}></div>
            <Text className={styles["ItemText"]}>Critical</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["NodeStatusYellow"]}></div>
            <Text className={styles["ItemText"]}>At risk</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["NodeStatusGreen"]}></div>
            <Text className={styles["ItemText"]}>On track</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["NodeStatusGrey"]}></div>
            <Text className={styles["ItemText"]}>n/a</Text>
          </div>
        </div>
      </div>
      <div className={styles["CategoryLegendBox"]}>
        <Text className={styles["CategoryTitle"]}>Purchasor</Text>
        <div className={styles["FrameCategory"]}>
          <div className={styles["ItemFrame"]}>
            <SettingOutlined />
            <Text className={styles["ItemText"]}>Picker</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <UserOutlined />
            <Text className={styles["ItemText"]}>Customer</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["PurchaseNodeRed"]}></div>
            <Text className={styles["ItemText"]}>Short pick</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["PurchaseNodeGreen"]}></div>
            <Text className={styles["ItemText"]}>Satisfied purchasor</Text>
          </div>
        </div>
      </div>
      <div className={styles["CategoryLegendBox"]}>
        <Text className={styles["CategoryTitle"]}>Number</Text>
        <div className={styles["FrameCategory"]}>
          <div className={styles["ItemFrame"]}>
            <div className={styles["NumberBoxBlue"]}>
              <Text className={styles["NumberWhite"]}>100</Text>
            </div>
            <Text className={styles["ItemText"]}># of people</Text>
          </div>
        </div>
      </div>
      <div className={styles["CategoryLegendBox"]}>
        <Text className={styles["CategoryTitle"]}>Ring</Text>
        <div className={styles["FrameCategory"]}>
          <div className={styles["ItemFrame"]}>
            <div className={styles["RingFull"]}>
              <div className={styles["RingFullInside"]}></div>
            </div>
            <Text className={styles["ItemText"]}>stock on shelf</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["PartialRing"]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 22 27"
                fill="none"
              >
                <path
                  d="M14 0.5C11.8453 0.5 9.7196 0.961841 7.78855 1.84956C5.8575 2.73728 4.17318 4.02691 2.86675 5.61803C1.56032 7.20916 0.667046 9.05882 0.256485 11.023C-0.154077 12.9872 -0.070842 15.0128 0.499707 16.9422C1.07026 18.8717 2.11272 20.6527 3.54592 22.1468C4.97912 23.6409 6.76437 24.8076 8.76265 25.5561C10.7609 26.3046 12.9183 26.6147 15.0668 26.4622C17.2153 26.3097 19.2968 25.6988 21.1494 24.6771L19.6684 22.3616C18.1995 23.1717 16.5492 23.6561 14.8458 23.777C13.1424 23.8978 11.4319 23.652 9.84762 23.0585C8.2633 22.4651 6.84788 21.5401 5.71158 20.3555C4.57529 19.171 3.74878 17.7589 3.29643 16.2291C2.84407 14.6994 2.77808 13.0934 3.10359 11.5361C3.4291 9.97886 4.13732 8.51237 5.17311 7.25086C6.2089 5.98935 7.5443 4.96688 9.07531 4.26306C10.6063 3.55924 12.2916 3.19308 14 3.19308L14 0.5Z"
                  fill="var(--adagocolors-bluepalette-blue-4)"
                />
              </svg>
            </div>
            <Text className={styles["ItemText"]}>In real cart</Text>
          </div>
          <div className={styles["ItemFrame"]}>
            <div className={styles["PartialRing"]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 22 27"
                fill="none"
              >
                <path
                  d="M14 0.5C11.8453 0.5 9.7196 0.961841 7.78855 1.84956C5.8575 2.73728 4.17318 4.02691 2.86675 5.61803C1.56032 7.20916 0.667046 9.05882 0.256485 11.023C-0.154077 12.9872 -0.070842 15.0128 0.499707 16.9422C1.07026 18.8717 2.11272 20.6527 3.54592 22.1468C4.97912 23.6409 6.76437 24.8076 8.76265 25.5561C10.7609 26.3046 12.9183 26.6147 15.0668 26.4622C17.2153 26.3097 19.2968 25.6988 21.1494 24.6771L19.6684 22.3616C18.1995 23.1717 16.5492 23.6561 14.8458 23.777C13.1424 23.8978 11.4319 23.652 9.84762 23.0585C8.2633 22.4651 6.84788 21.5401 5.71158 20.3555C4.57529 19.171 3.74878 17.7589 3.29643 16.2291C2.84407 14.6994 2.77808 13.0934 3.10359 11.5361C3.4291 9.97886 4.13732 8.51237 5.17311 7.25086C6.2089 5.98935 7.5443 4.96688 9.07531 4.26306C10.6063 3.55924 12.2916 3.19308 14 3.19308L14 0.5Z"
                  fill="var(--adagocolors-bluepalette-blue-8"
                />
              </svg>
            </div>
            <Text className={styles["ItemText"]}>In online cart</Text>
          </div>
        </div>
      </div>
    </div>
  );
}
