import React from "react";
interface OnlineArrow {
  containerHeight: number;
}

const Arrow = ({ containerHeight }: OnlineArrow) => {
  const shaftEndY = (containerHeight - 350) / 4; // Das Ende des Pfeilschaftes
  const arrowHeight = shaftEndY + 10; // Höhe der gesamten SVG (Pfeilschaft + Pfeilkopf)

  return (
    <svg
      viewBox={`0 0 200 ${arrowHeight}`}
      style={{ height: arrowHeight }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="5"
          markerHeight="5"
          fill="#D9D9D9"
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
      </defs>
      <line
        x1="50%"
        y1="0%"
        x2="50%"
        y2="90%"
        stroke="#D9D9D9"
        fill="#D9D9D9"
        width={1}
        markerEnd="url(#arrow)"
      />
    </svg>
  );
};

export default Arrow;
