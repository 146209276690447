//Sider Object
import { Layout } from "antd";
import { SiderMenu } from "../../data/menus";
import { CSSProperties } from "react";

interface SiderObjectProps {
  token: {
    padding: number;
    lineWidth: number;
    colorSplit: string;
  };
  siderCollapsed: boolean;
}

const { Sider } = Layout;
export default function SiderObject({
  token,
  siderCollapsed
}: SiderObjectProps) {
  const styles: { [key: string]: CSSProperties } = {
    logoWrapper: {
      padding: `${token.padding}px 28px ${token.padding}px 28px`
    },
    sider: {
      borderRight: `${token.lineWidth}px solid ${token.colorSplit}`,
      //display: screens.sm ? "block" : "none",
      overflow: "auto",
      height: "100vh",
      position: "fixed",
      background: "var(--Colors-Brand-Primary-colorPrimaryBg)"
    },
    siderContent: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }
  };
  return (
    <Sider
      style={styles.sider}
      width={256}
      theme="light"
      trigger={null}
      collapsible
      collapsed={siderCollapsed}
    >
      <div style={styles.siderContent}>
        <SiderMenu />
      </div>
    </Sider>
  );
}
