import React, { CSSProperties, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

import { BoxPlotOutlined, DashboardOutlined } from "@ant-design/icons";
import { ItemType } from "antd/es/menu/interface";

const items: ItemType[] = [
  {
    key: "overview",
    icon: <DashboardOutlined />,
    label: "Overview"
  },
  {
    key: "simulation",
    icon: <BoxPlotOutlined />,
    label: "Simulator"
  }
];

export const SiderMenu: React.FC = () => {
  const navigate = useNavigate();
  const styles: { [key: string]: CSSProperties } = {
    navMenu: {
      backgroundColor: "transparent",
      border: 0,
      flexGrow: 1
    }
  };
  const [selectedKey, setSelectedKey] = useState(["1"]);
  useEffect(() => {
    setSelectedKey(window.location.pathname.split("/").slice(-1));
  }, []);
  const handleMenuClick = (menuInfo: { key: string }) => {
    setSelectedKey([menuInfo.key]);
    navigate(`/${menuInfo.key}`);
  };
  return (
    <Menu
      theme="light"
      mode="inline"
      style={styles.navMenu}
      defaultSelectedKeys={["filter-selection"]}
      selectedKeys={selectedKey}
      defaultOpenKeys={["simulation/test"]}
      items={items}
      onClick={handleMenuClick}
    />
  );
};
