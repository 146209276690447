import React, { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { Navigate, useLocation } from "react-router-dom";
import BackgroundImage from "../assets/images/login_background_normal_resolution.jpg";
import { CSSProperties } from "react";

import { Button, Grid, theme, Typography } from "antd";
import LogoImage from "../assets/logos/logo.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

function LoginForm() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const { instance } = useMsal();

  const handleLogin = async (): Promise<void> => {
    try {
      await instance.loginRedirect({
        scopes: ["User.read"],
        prompt: "select_account"
      });
      console.log("User logged in successfully.");
    } catch (error) {
      console.error(error);
    }
  };

  const styles: { [key: string]: CSSProperties } = {
    container: {
      display: "flex",
      width: "380px",
      maxWidth: "380px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: token.marginLG,
      alignSelf: "stretch",
      marginTop: "20vh",
      padding: token.paddingLG,
      borderRadius: token.borderRadiusLG,
      backgroundColor:
        token.colorBgContainer /* Background will be transparent */
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignSelf: "stretch"
    },
    section: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      padding: token.paddingSM
    },
    text: {
      color: token.colorTextSecondary,
      display: "flex",
      alignItems: "center",
      alignSelf: "stretch",
      textAlign: "center"
    },
    title: {
      display: "flex",
      flex: "1 0 0 ",
      justifyContent: "center",
      alignItems: "center",
      gap: token.marginXS,
      alignSelf: "stretch",
      padding: token.paddingXS,
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    },
    logo: {
      width: "50px",
      height: "50px",
      alignSelf: "center"
    }
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <img src={LogoImage} alt="Logo" style={styles.logo} />

          <Title style={styles.title}>Sign in</Title>
          <Text style={styles.text}>
            Welcome back to IntelliTwin. Please press the button below to sign
            in.
          </Text>
        </div>
        <Button type="primary" onClick={handleLogin}>
          Login with Microsoft
        </Button>
      </div>
    </section>
  );
}
const Login: React.FC = () => {
  const [authenticatedRedirect, setAuthenticatedRedirect] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // redirect to where you're comfing from if redirected because of unauthenticated, otherwise to home page
    let redirectTo: string = location.state
      ? location.state.comingFrom
      : "/home";
    redirectTo =
      redirectTo.trim() !== "/" && redirectTo.trim() !== "/login"
        ? redirectTo.trim()
        : "/home";
    setAuthenticatedRedirect(redirectTo);
  }, [location.state]);

  const styles: { [key: string]: CSSProperties } = {
    container: {
      display: "flex",
      backgroundImage: `url(${BackgroundImage})`,
      backgroundColor: "lightgray",
      minHeight: "100vh", // Makes the container take up the full height of the viewport
      backgroundPosition: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center"
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginLeft: "20px",
      marginRight: "20px",
      paddingBottom: "20px"
    },
    links: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    buttonStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5rem",
      color: "white",
      textDecoration: "none"
    },
    text: {
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem"
    }
  };

  return (
    <>
      <UnauthenticatedTemplate>
        <div style={styles.container}>
          <LoginForm />
          <div style={styles.footer}>
            <div style={styles.links}>
              <Button
                type="text"
                style={styles.buttonStyle}
                href="https://www.adago.de/about-us"
              >
                About
              </Button>
              <Button
                type="text"
                style={styles.buttonStyle}
                href="https://www.adago.de/data-protection"
              >
                Privacy
              </Button>
              <Button
                type="text"
                style={styles.buttonStyle}
                href="https://www.adago.de/solutions"
              >
                Solutions
              </Button>
            </div>
            <Text style={styles.text}>© 2024 adago IntelliTwin</Text>
          </div>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navigate to={authenticatedRedirect} />
      </AuthenticatedTemplate>
    </>
  );
};

export default Login;
