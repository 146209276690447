import React, { useState, CSSProperties, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Grid, Layout, theme } from "antd";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";
import SiderObject from "./components/AppShell/SiderObject";
import { Topbar } from "./components/AppShell/TopBar";
import Login from "./components/Login";
import Private from "./components/PrivateRoute";
import { OurConfigProvider } from "./styles/ConfigProvider";
import ComingSoon from "./components/Common/ComingSoon";
import LandingPage from "./components/LandingPage/LandingPage";
import MainVisualization from "./components/SimulationEngine/MainVisualization";
import SimulationProvider from "./components/SimulationEngine/SimulationProvider";
import FigmaToCode from "./components/FigmaToCode/FigmaToCode";
const { Header, Content } = Layout;

const { useToken } = theme;
const { useBreakpoint } = Grid;

const siderWidthNotCollapsed = 256; // changes the size of the extended sider
const siderWidthCollapsed = 80; // doesn't change the size of collapsed sider, just need to know its width

export default function App() {
  const { token } = useToken();
  const screens = useBreakpoint();

  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState(siderWidthNotCollapsed);

  useEffect(() => {
    if (menuCollapsed) {
      setSiderWidth(siderWidthCollapsed);
    } else {
      setSiderWidth(siderWidthNotCollapsed);
    }
  }, [menuCollapsed]);

  const styles: { [key: string]: CSSProperties } = {
    layout: {
      minHeight: "100vh",
      height: "fit-content"
    },
    panel: {
      backgroundColor: "var(--Colors-Neutral-colorBgBase)",
      borderRadius: token.borderRadiusLG,
      padding: screens.lg ? token.paddingLG : token.padding
    },
    placeholder: {
      backgroundColor: "var(--Colors-Neutral-colorBgBase)",
      border: `${token.lineWidth}px dashed ${token.colorBorder}`,
      borderRadius: token.borderRadiusLG,
      height: "100%",
      padding: token.paddingLG,
      textAlign: "center"
    },
    section: {
      backgroundColor: "var(--Colors-Brand-Primary-colorPrimaryBg)",
      height: "100%",
      width: "100%"
    },
    innerSection: {
      backgroundColor: "var(--Colors-Neutral-colorBgBase)",
      borderTopLeftRadius: "var(--Border-Radius-borderRadius)",
      padding: screens.lg
        ? `${token.paddingLG}px ${token.paddingLG}px`
        : `${token.padding}px ${token.padding}px`,
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      alignItems: "center",
      padding: "0px",
      background: "var(--Colors-Brand-Primary-colorPrimaryBg)"
    },
    content: { flexGrow: 1, display: "flex", marginLeft: `${siderWidth}px` }
  };

  return (
    <MsalProvider instance={msalInstance}>
      <OurConfigProvider>
        <Router>
          <Routes>
            {/* Define the landing page at the root "/" */}
            <Route path="/login" element={<Login />} />

            {/* Define other routes that require the main layout */}
            <Route
              path="*"
              element={
                <Private>
                  <Layout style={styles.layout}>
                    <Header style={styles.header}>
                      <Topbar
                        menuCollapsed={menuCollapsed}
                        setMenuCollapsed={setMenuCollapsed}
                        siderWidthCollapsed={siderWidthCollapsed}
                      />
                    </Header>
                    <Layout>
                      <SiderObject
                        token={token}
                        siderCollapsed={menuCollapsed}
                      />
                      <Content style={styles.content}>
                        <div style={styles.section}>
                          <div
                            style={{
                              display: "flex",
                              height: "100%"
                            }}
                          >
                            <div style={styles.innerSection}>
                              <Routes>
                                <Route path="/home" element={<LandingPage />} />
                                <Route
                                  path="/overview"
                                  element={<ComingSoon />}
                                />
                                <Route
                                  path="/simulation"
                                  element={
                                    <SimulationProvider>
                                      {(sharedProps) => (
                                        <MainVisualization
                                          props={sharedProps}
                                        />
                                      )}
                                    </SimulationProvider>
                                  }
                                />
                                <Route
                                  path="/figma-to-code"
                                  element={<FigmaToCode />}
                                />
                                <Route
                                  path="/*"
                                  element={<Navigate to="/home" />}
                                />
                              </Routes>
                            </div>
                          </div>
                        </div>
                      </Content>
                    </Layout>
                  </Layout>
                </Private>
              }
            />
          </Routes>
        </Router>
      </OurConfigProvider>
    </MsalProvider>
  );
}
