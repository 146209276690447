import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions
} from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

interface GoodsRingProps {
  stock: number;
  cart: number;
  online: number;
}

const radius = 25;

export default function GoodsRing(props: GoodsRingProps) {
  const cartColor = getComputedStyle(document.body).getPropertyValue(
    "--adagocolors-bluepalette-blue-4"
  );
  const onlineColor = getComputedStyle(document.body).getPropertyValue(
    "--adagocolors-bluepalette-blue-8"
  );
  const borderColorStock = getComputedStyle(document.body).getPropertyValue(
    "--adagocolors-bluepalette-blue-2"
  );
  const data = [
    {
      label: "available stock",
      value:
        props.stock - props.online - props.cart > 0
          ? props.stock - props.online - props.cart
          : 0.001, // if all values are 0, chart doesn't get drawn
      color: "white",
      borderColor: borderColorStock
    },
    {
      label: "in online cart",
      value: props.online,
      color: onlineColor,
      borderColor: onlineColor
    },
    {
      label: "in real cart",
      value: props.cart,
      color: cartColor,
      borderColor: cartColor
    }
  ];

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: "80%",
    radius: radius,
    events: []
  };

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.borderColor),
        borderWidth: 1
      }
    ]
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
        height: radius * 2,
        width: radius * 2
      }}
    >
      <Doughnut data={finalData} options={options} />
    </div>
  );
}
