import React from "react";
import styles from "../../styles/Common/StatsCard.module.css";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { Typography } from "antd";
import { Tiny } from "@ant-design/plots";
import { SmileOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

interface StatsCardSimpleProps {
  icon: React.ReactNode;
  title: string;
  value: number | string;
}

interface StatsCardUpDownProps {
  icon: React.ReactNode;
  title: string;
  value: number | string;
  arrowUp: boolean;
  arrowNumber: string | number;
  arrowText: string;
}

interface StatsCardRingProps {
  title: string;
  percent: number; // number in [0,1]
  text?: string;
}
export function StatsCardArrow({
  icon = <SmileOutlined className={styles["iconSmiley"]} />, // standard icon if none is passed
  title,
  value,
  arrowUp,
  arrowNumber,
  arrowText
}: StatsCardUpDownProps): React.ReactNode {
  let arrow = <ArrowDown className={styles["iconArrowDown"]} />;
  let arrowNumberComponent = (
    <Text className={styles["NumbersDelta"]}>{arrowNumber}</Text>
  );
  if (arrowUp) {
    arrow = <ArrowUp className={styles["iconArrowUp"]} />;
    arrowNumberComponent = (
      <Text className={styles["NumbersDeltaUp"]}>{arrowNumber}</Text>
    );
  }
  // round numbers to 2 decimal places
  if (typeof arrowNumber === "number") {
    arrowNumber = Math.round(arrowNumber * 100) / 100;
  }
  if (typeof value === "number") {
    value = Math.round(value * 100) / 100;
  }

  return (
    <div className={styles["standardDowndefault"]}>
      <div className={styles["defaultUpperThird"]}>
        <div className={styles["iconBoxBlue"]}>
          {React.cloneElement(icon as React.ReactElement, {
            className: styles["iconSmiley"]
          })}
        </div>
        <div className={styles["TextBox"]}>
          <Text className={styles["StatisticTitle"]}>{title}</Text>
          <Title className={styles["StatsNumbers"]} level={5}>
            {value}
          </Title>
        </div>
      </div>
      <div className={styles["deltaFrame"]}>
        {arrow}
        {arrowNumberComponent}
        <Text className={styles["TextDelta"]}>{arrowText}</Text>
      </div>
    </div>
  );
}

export function StatsCardSimple({
  icon = <ArrowDown className={styles["iconSmiley"]} />, // Standardicon if none is passed
  title,
  value
}: StatsCardSimpleProps): React.ReactNode {
  // round numbers to 2 decimal places
  if (typeof value === "number") {
    value = Math.round(value * 100) / 100;
  }

  return (
    <div className={styles["standardDowndefault"]}>
      <div className={styles["defaultUpperThirdNoLine"]}>
        <div className={styles["iconBoxBlue"]}>
          {React.cloneElement(icon as React.ReactElement, {
            className: styles["iconSmiley"]
          })}
        </div>
        <div className={styles["TextBox"]}>
          <Text className={styles["StatisticTitle"]}>{title}</Text>
          <Title className={styles["StatsNumbers"]} level={5}>
            {value}
          </Title>
        </div>
      </div>
    </div>
  );
}

export function StatsCardRing({
  title,
  percent,
  text
}: StatsCardRingProps): React.ReactNode {
  if (!percent || percent === 0) {
    percent = 0.00001; // otherwise no ring gets displayed
  }
  const primarycolor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--Colors-Brand-Primary-colorPrimary");
  const ringbackgroundcolor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--Colors-Brand-Primary-colorPrimaryBgHover");
  const ringconfig = {
    percent,
    width: 36,
    height: 36,
    color: [ringbackgroundcolor, primarycolor]
  };
  const percentageText = Math.round(percent * 100).toString() + " %";
  return (
    <div className={styles["standardDowndefault"]}>
      <div className={styles["defaultUpperThirdNoLine"]}>
        <div className={styles["ringBox"]}>
          <Tiny.Ring {...ringconfig} />
        </div>
        <div className={styles["TextBox"]}>
          <Text className={styles["StatisticTitle"]}>{title}</Text>
          <Title className={styles["StatsNumbers"]} level={5}>
            {text ? text : percentageText}
          </Title>
        </div>
      </div>
    </div>
  );
}
