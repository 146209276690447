import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-moment";
import "../../styles/colors.css";
import {
  ChartData,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  TooltipItem
} from "chart.js";
import styles from "../../styles/Common/DatePlotGraphic.module.css";

interface DateLinePlotProps {
  plotData: DatePlotData[];
  unit: string;
}

export interface DatePlotData {
  label: string;
  dataset: DatePoint[];
  color?: string;
}

export interface DatePoint {
  x: string;
  y: number;
}

// setup chart js
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);
ChartJS.defaults.color = "var(--Colors-Neutral-colorTextBase)";
const warning = getComputedStyle(document.body).getPropertyValue(
  "--Colors-Brand-Warning-colorWarningText"
);
const primary = getComputedStyle(document.body).getPropertyValue(
  "--Colors-Brand-Primary-colorPrimary"
);
const error = getComputedStyle(document.body).getPropertyValue(
  "--Colors-Brand-Error-colorError"
);
const success = getComputedStyle(document.body).getPropertyValue(
  "--Colors-Brand-Success-colorSuccess"
);
const lineColors = [primary, success, warning, error];

function getAllDates(plotData: DatePlotData[]): string[] {
  const dateSet = new Set<string>();
  plotData.forEach((data) =>
    data.dataset.forEach((point) => dateSet.add(point.x))
  );
  return Array.from(dateSet);
}

const DateLinePlot = ({ plotData, unit }: DateLinePlotProps) => {
  const options = {
    maintainAspectRatio: false as const,
    scales: {
      y: {
        beginAtZero: true,
        grid: { display: false },
        title: {
          display: true,
          text: unit
        }
      },
      x: {
        grid: { display: false },
        type: "time" as const
      }
    },
    plugins: {
      // colors: {
      //   forceOverride: true
      // },
      tooltip: {
        callbacks: {
          // displayed format of date in tooltip depends on aggregation level
          // TODO: change dateformat in tooltip
          title: function (context: TooltipItem<"line">[]) {
            const date = new Date((context[0].raw as DatePoint).x);
            return date.toLocaleDateString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric"
            });
          }
        }
      }
    },
    elements: {
      point: {
        pointStyle: false as const
      }
    },
    interaction: {
      mode: "nearest" as const
    }
  };
  const data: ChartData<"line", DatePoint[], string> = {
    labels: getAllDates(plotData),
    datasets: plotData.map((onePlotData, index) => {
      const lineColor = onePlotData.color
        ? onePlotData.color
        : lineColors[index % lineColors.length];

      return {
        label: onePlotData.label,
        data: onePlotData.dataset,
        parsing: {
          xAxisKey: "x",
          yAxisKey: "y"
        },
        borderColor: lineColor,
        backgroundColor: lineColor
      };
    })
  };
  // BUG: Bar is only supporting bar type and not line shart.
  return (
    <div className={styles["date-plot-container"]}>
      <Line data={data} options={options} />
    </div>
  );
};

export default DateLinePlot;
