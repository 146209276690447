import React, { useEffect, useRef, useState } from "react";
import {
  CustomerStateMessage,
  GoodsStateMessage,
  OnlineCustomerStateMessage,
  PlayerType,
  StoreStateMessage
} from "../../../../models/messageSpec";
import GoodsDisplayInStore from "./GoodsInStore";
import CustomerDisplayInStore from "./CustomerInStore";
import ShoppingRoute from "./ShoppingRoute";
import { getMessage } from "../../../../utils/messageUtils";
import useResizeObserver from "@react-hook/resize-observer";
import { SidebarContentType } from "../../../../types/SidebarTypes";
import { coordinatePointToString } from "../../../../utils/coordinatePointUtils";

interface SimulationVisualizationProps {
  currentCycle: number;
  storeStateMessages: StoreStateMessage[];
  customerStateMessages: CustomerStateMessage[];
  goodsStateMessages: GoodsStateMessage[];
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  simulationSpeed: number;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  sidebarObjectId: (string | number)[];
  sidebarContentType: SidebarContentType;
}
export default function SimulationVisualization(
  props: SimulationVisualizationProps
) {
  // animating customers
  // Ref für das Div, das die Größe überwacht
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [storeHeight, setStoreHeight] = useState<number>(0);
  const [storeWidth, setStoreWidth] = useState<number>(0);
  // keep track of current messages
  const [currentStoreState, setCurrentStoreState] =
    useState<StoreStateMessage>();
  const [currentCustomerMessage, setCurrentCustomerMessage] =
    useState<CustomerStateMessage>();
  const [currentGoodsMessage, setCurrentGoodsMessage] =
    useState<GoodsStateMessage>();
  const [currentOnlineCustomerMessage, setCurrentOnlineCustomerMessage] =
    useState<OnlineCustomerStateMessage>();
  // keep track of customers at checkout
  const [atCheckout, setAtCheckout] = useState<string[]>([]);

  // update storeHeight and storeWidth
  useResizeObserver(containerRef, (entry) => {
    setStoreHeight(entry.contentRect.height);
    setStoreWidth(entry.contentRect.width);
  });

  // update the current messages if cycle changes
  useEffect(() => {
    if (props.currentCycle) {
      setCurrentStoreState(
        getMessage(
          props.currentCycle,
          props.storeStateMessages
        ) as StoreStateMessage
      );
      setCurrentCustomerMessage(
        getMessage(
          props.currentCycle,
          props.customerStateMessages
        ) as CustomerStateMessage
      );
      setCurrentGoodsMessage(
        getMessage(
          props.currentCycle,
          props.goodsStateMessages
        ) as GoodsStateMessage
      );
      setCurrentOnlineCustomerMessage(
        getMessage(
          props.currentCycle,
          props.onlineCustomerMessages
        ) as OnlineCustomerStateMessage
      );
    }
  }, [props]);

  // keep track of customers at checkout
  useEffect(() => {
    if (currentStoreState && currentStoreState.route.length > 0) {
      const peopleAtCheckout: string[] = [];
      const checkoutCoords =
        currentStoreState.route[currentStoreState.route.length - 1];
      const checkoutCoordString = coordinatePointToString(checkoutCoords);
      // compare the coordinates of the customer (no pickers) to the coordinates of the checkout and count the people
      Object.keys(currentStoreState.customer_coordinates).forEach(
        (customerId) => {
          const customerCoordString = coordinatePointToString(
            currentStoreState.customer_coordinates[customerId]
          );
          if (
            customerCoordString === checkoutCoordString &&
            currentCustomerMessage.customer_id_dict[customerId].player_type ===
              PlayerType.customer
          ) {
            peopleAtCheckout.push(customerId);
          }
        }
      );
      setAtCheckout(peopleAtCheckout);
    }
  }, [currentStoreState, currentCustomerMessage]);

  return (
    <div
      ref={containerRef}
      style={{
        // HEIGHT MUSS NOCH INTERAKTIV WERDEN!
        height: `100%`,
        boxSizing: "border-box",
        position: "relative"
      }}
    >
      <ShoppingRoute {...props} atCheckout={atCheckout} />
      <GoodsDisplayInStore
        {...props} // Neue Prop für Klick-Ereignisse
        storeStateMessage={currentStoreState}
        goodsStateMessage={currentGoodsMessage}
        customerMessage={currentCustomerMessage}
        onlineCustomerMessage={currentOnlineCustomerMessage}
        storeHeight={storeHeight}
        storeWidth={storeWidth}
      />
      <CustomerDisplayInStore
        customerStateMessage={currentCustomerMessage}
        storeStateMessage={currentStoreState}
        storeHeight={storeHeight}
        storeWidth={storeWidth}
        atCheckout={atCheckout}
        {...props}
      />
    </div>
  );
}
