import React, { useState, useEffect, useRef } from "react";
import {
  CustomerStateMessage,
  StoreStateMessage,
  CoordinatePoint
} from "../../../../models/messageSpec";
import Customer from "../../Store/OfflineStore/Customer";
import {
  addPoints,
  coordinatePointToString,
  scalarMultiplication,
  stringToCoordinatePoint
} from "../../../../utils/coordinatePointUtils";
import { SidebarContentType } from "../../../../types/SidebarTypes";

const OVERLAPPING_ADJUSTEMENT: CoordinatePoint = {
  x: 3,
  y: 3
};

interface CustomerDisplayProps {
  customerStateMessage: CustomerStateMessage;
  storeStateMessage: StoreStateMessage;
  storeWidth: number;
  storeHeight: number;
  simulationSpeed: number;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  sidebarObjectId: (string | number)[];
  sidebarContentType: SidebarContentType;
  atCheckout: string[];
}

export default function CustomerDisplayInStore({
  customerStateMessage,
  storeStateMessage,
  storeHeight,
  storeWidth,
  simulationSpeed,
  setSidebarContentType,
  setSidebarObjectId,
  sidebarObjectId,
  sidebarContentType,
  atCheckout
}: CustomerDisplayProps) {
  // keep track which customers are at the same position
  // format: stringCoordinate: customerId[]
  const customerOverlap = useRef<{ [k: string]: string[] }>({});
  // keep track which customer should be where (including scaling coordinates and adjusting for overlap)
  const [customerCoordinates, setCustomerCoordinates] = useState<{
    [k: string]: CoordinatePoint;
  }>({});

  // keep overlappingCustomers up to date
  useEffect(() => {
    const overlappingCustomers: {
      [k: string]: string[];
    } = {};
    if (storeStateMessage) {
      // sort customers according to their position
      Object.keys(storeStateMessage.customer_coordinates).forEach(
        (customer_id) => {
          const stringCoordinates = coordinatePointToString(
            storeStateMessage.customer_coordinates[customer_id]
          );
          if (overlappingCustomers[stringCoordinates]) {
            overlappingCustomers[stringCoordinates].push(customer_id);
          } else {
            overlappingCustomers[stringCoordinates] = [customer_id];
          }
        }
      );
      customerOverlap.current = overlappingCustomers;
    } else {
      customerOverlap.current = {};
    }
  }, [storeStateMessage]);

  // keep track of the adjusted customer coordinates
  useEffect(() => {
    const newCustomerCoords = {};
    // for each customer adjust the coordinates by their overlap
    Object.keys(customerOverlap.current).forEach((stringCoordinate: string) => {
      for (
        let i = 0;
        i < customerOverlap.current[stringCoordinate].length;
        i++
      ) {
        const customerId = customerOverlap.current[stringCoordinate][i];
        const coordinates = stringToCoordinatePoint(stringCoordinate);
        const scaledCoordinates = {
          x: coordinates.x * storeWidth,
          y: coordinates.y * storeHeight
        };
        newCustomerCoords[customerId] = addPoints(
          scaledCoordinates,
          scalarMultiplication(OVERLAPPING_ADJUSTEMENT, i)
        );
      }
    });
    setCustomerCoordinates(newCustomerCoords);
  }, [customerOverlap.current, storeHeight, storeWidth]);

  // handle the selection of a customer
  const handleCustomerSelection = (customerId: string) => {
    const customerOgCoords = storeStateMessage.customer_coordinates[customerId];
    const selectedCustomers =
      customerOverlap.current[coordinatePointToString(customerOgCoords)];
    setSidebarContentType(SidebarContentType.CUSTOMER);
    setSidebarObjectId(selectedCustomers);
  };

  // for each customer, create a customer at the adjusted coordinates
  return (
    <>
      {Object.keys(customerCoordinates).map((customerId) => {
        if (
          customerStateMessage.customer_id_dict[customerId] &&
          !atCheckout.includes(customerId)
        ) {
          return (
            <Customer
              key={customerId}
              coordinates={customerCoordinates[customerId]}
              customerInstance={
                customerStateMessage.customer_id_dict[customerId]
              }
              simulationSpeed={simulationSpeed}
              entranceCoordinates={{
                x: storeStateMessage.route[0].x * storeWidth,
                y: storeStateMessage.route[0].y * storeHeight
              }}
              handleCustomerSelection={handleCustomerSelection}
              selected={
                sidebarObjectId.includes(customerId) &&
                sidebarContentType === SidebarContentType.CUSTOMER
              }
            />
          );
        }
        return <></>;
      })}
    </>
  );
}
