// src/components/PrivateRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";

interface PrivateRouteProps {
  children: JSX.Element;
}

export default function PrivateRoute({
  children
}: PrivateRouteProps): JSX.Element {
  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate
          to="/login"
          state={{ comingFrom: window.location.pathname }}
        />
      </UnauthenticatedTemplate>
    </>
  );
}
