import React, { useRef, useState, useLayoutEffect } from "react";
import styles from "../../../styles/SimulationEngine/Store/Store.module.css";
import { Person } from "react-bootstrap-icons";
import { Typography } from "antd";
import { OnlineNodes } from "./OnlineStore/OnlineNode";
import {
  GoodsStateMessage,
  CustomerStateMessage,
  StoreStateMessage,
  OnlineCustomerStateMessage
} from "../../../models/messageSpec";
import Arrow from "./OnlineStore/OnlineArrow";
import SimulationVisualization from "./OfflineStore/SimulationVisualization";
import { SidebarContentType } from "../../../types/SidebarTypes";

const { Text } = Typography;

interface StoreProps {
  storeStateMessages: StoreStateMessage[];
  customerStateMessages: CustomerStateMessage[];
  goodsStateMessages: GoodsStateMessage[];
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  currentCycle: number;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  simulationSpeed: number;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  sidebarObjectId: (string | number)[];
  sidebarContentType: SidebarContentType;
}
export default function Store(props: StoreProps) {
  const onlineStoreRef = useRef<HTMLDivElement>(null);
  const offlineStoreRef = useRef<HTMLDivElement | null>(null);
  const [onlineStoreHeight, setOnlineStoreHeight] = useState<number>(0);
  const [onlineStoreWidth, setOnlineStoreWidth] = useState<number>(0);
  const [, setOfflineStoreWidth] = useState<number>(0);
  const [offlineStoreHeight, setOfflineStoreHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const online = onlineStoreRef.current;

    if (online) {
      const resizeObserverOnline = new ResizeObserver((entries) => {
        if (entries[0].contentRect.height) {
          setOnlineStoreHeight(entries[0].contentRect.height);
          setOnlineStoreWidth(entries[0].contentRect.width);
        }
      });

      resizeObserverOnline.observe(online);

      // Cleanup Funktion, um den Observer bei unmount zu stoppen
      return () => resizeObserverOnline.unobserve(online);
    }
  }, []);
  useLayoutEffect(() => {
    const offline = offlineStoreRef.current;
    if (offline) {
      const resizeObserverOffline = new ResizeObserver((entries) => {
        if (entries[0].contentRect.height) {
          setOfflineStoreHeight(entries[0].contentRect.height);
          setOfflineStoreWidth(entries[0].contentRect.width);
        }
      });

      resizeObserverOffline.observe(offline);

      // Cleanup Funktion, um den Observer bei unmount zu stoppen
      return () => resizeObserverOffline.unobserve(offline);
    }
  }, []);

  const arrowHeight = offlineStoreHeight / 10;

  return (
    <div className={styles["BothStoresFrame"]}>
      <div className={styles["LeftSide"]}>
        <Text className={styles["StoreName"]}>Online Shop</Text>
        <div className={styles["OnlineStore"]} ref={onlineStoreRef}>
          <OnlineNodes
            icon={<Person />}
            title="Shopping"
            value={20}
            style={{ backgroundColor: "#0033B3" }}
            {...props}
          ></OnlineNodes>
          <Arrow containerHeight={onlineStoreHeight}></Arrow>
          <OnlineNodes
            icon={<Person />}
            title="Checkout"
            value={20}
            style={{ backgroundColor: "#0045D9" }}
            {...props}
          ></OnlineNodes>
          <Arrow containerHeight={onlineStoreHeight}></Arrow>
          <OnlineNodes
            icon={<Person />}
            title="Payment"
            value={20}
            style={{ backgroundColor: "#0D61FF" }}
            {...props}
          ></OnlineNodes>
          <Arrow containerHeight={onlineStoreHeight}></Arrow>
          <OnlineNodes
            icon={<Person />}
            title="Waiting"
            value={20}
            style={{ backgroundColor: "#3683FF" }}
            {...props}
          ></OnlineNodes>
          <Arrow containerHeight={onlineStoreHeight}></Arrow>
          <OnlineNodes
            icon={<Person />}
            title="Picker"
            value={20}
            style={{ backgroundColor: "#5EA1FF" }}
            {...props}
          ></OnlineNodes>
        </div>
        <svg
          viewBox={`0 0 ${onlineStoreWidth} ${arrowHeight}`}
          style={{ height: "10%" }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="10"
              markerHeight="10"
              fill="#D9D9D9"
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
          </defs>
          <line
            x1="50%"
            y1="0"
            x2="75%"
            y2="75%"
            stroke="#D9D9D9"
            fill="#D9D9D9"
            width={1}
            markerEnd="url(#arrow)"
          />
        </svg>
        <svg
          viewBox={`0 0 ${onlineStoreWidth} 
        ${arrowHeight}`}
          style={{ height: "10%" }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="10"
              markerHeight="10"
              fill="#D9D9D9"
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
          </defs>
          <line
            x1="50%"
            y1="100%"
            x2="75%"
            y2="25%"
            stroke="#D9D9D9"
            fill="#D9D9D9"
            width={1}
            markerEnd="url(#arrow)"
          />
        </svg>

        <div className={styles["CustomerFrame"]}>
          <OnlineNodes
            icon={<Person />}
            title="Customer"
            value={20}
            style={{ backgroundColor: "#87BDFF" }}
            {...props}
          ></OnlineNodes>
        </div>
      </div>

      <div className={styles["StoreFrame"]} ref={offlineStoreRef}>
        <Text className={styles["StoreName"]}>Offline Store</Text>
        <div className={styles["StoreBorder"]}>
          <SimulationVisualization {...props} />
        </div>
      </div>
    </div>
  );
}
