import React, { useEffect, useState } from "react";
import styles from "../../../../styles/SimulationEngine/Store/SideDrawer/GoodsAndGeneralInfo.module.css";
import { Typography } from "antd";
import { StatsCardRing, StatsCardSimple } from "./StatsCardsWoolworth";
import { MoneyCollectOutlined } from "@ant-design/icons";
import {
  CustomerStateMessage,
  OnlineCustomerStateMessage
} from "../../../../models/messageSpec";
import { StoreInfo } from "../../../../types/SimulationSharedProps";
const { Text } = Typography;

interface GeneralInformationProps {
  customerStateMessage: CustomerStateMessage;
  onlineCustomerStateMessage: OnlineCustomerStateMessage;
  selectedStore: StoreInfo;
}

interface CustomerStats {
  onlineTotal: number;
  offlineTotal: number;
  onlinePercents: number;
}
const initCustomerStats: CustomerStats = {
  onlineTotal: 0,
  offlineTotal: 0,
  onlinePercents: 0
};

export default function GeneralInformation(
  props: GeneralInformationProps
): React.ReactNode {
  const [customerStats, setCustomerStats] =
    useState<CustomerStats>(initCustomerStats);

  useEffect(() => {
    if (props.onlineCustomerStateMessage && props.customerStateMessage) {
      const newCustomerStats: CustomerStats = {
        onlineTotal: props.onlineCustomerStateMessage.total_customer_count,
        offlineTotal: props.customerStateMessage.total_customer_count,
        onlinePercents:
          props.onlineCustomerStateMessage.total_customer_count /
          (props.onlineCustomerStateMessage.total_customer_count +
            props.customerStateMessage.total_customer_count)
      };
      setCustomerStats(newCustomerStats);
    } else {
      setCustomerStats(initCustomerStats);
    }
  }, [props]);

  return (
    <div className={styles["Box"]}>
      <div className={styles["GeneralInfo"]}>
        <Text className={styles["Titles"]}>
          {props.selectedStore
            ? `General Information - Store: ${props.selectedStore.id} (${props.selectedStore.name})`
            : "General Information "}
        </Text>
        <div className={styles["StatsCardsFrame"]}>
          <StatsCardSimple
            icon={<MoneyCollectOutlined />}
            title="Online Sales"
            value={"xx R"}
          ></StatsCardSimple>
          <StatsCardSimple
            icon={<MoneyCollectOutlined />}
            title="Offline Sales"
            value={"xx R"}
          ></StatsCardSimple>
          <StatsCardSimple
            icon={<MoneyCollectOutlined />}
            title="Customer Online"
            value={customerStats.onlineTotal}
          ></StatsCardSimple>
          <StatsCardSimple
            icon={<MoneyCollectOutlined />}
            title="Customer Offline"
            value={customerStats.offlineTotal}
          ></StatsCardSimple>
          <StatsCardRing
            title="Percentage of online purchases"
            percent={customerStats.onlinePercents}
          ></StatsCardRing>
        </div>
      </div>
    </div>
  );
}
