import React from "react";
import {
  CustomerStateMessage,
  OnlineCustomerStateMessage,
  StoreStateMessage
} from "../../../../models/messageSpec";
import Entrance from "./Entrance";
import Exit from "./Exit";
import { SidebarContentType } from "../../../../types/SidebarTypes";

interface RouteProps {
  storeStateMessages: StoreStateMessage[];
  customerStateMessages: CustomerStateMessage[];
  onlineCustomerMessages: OnlineCustomerStateMessage[];
  currentCycle: number;
  atCheckout: string[];
  setSidebarObjectId: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  setSidebarContentType: React.Dispatch<
    React.SetStateAction<SidebarContentType>
  >;
}

export default function ShoppingRoute(props: RouteProps): JSX.Element {
  if (
    !props.storeStateMessages ||
    props.storeStateMessages.length === 0 ||
    !props.storeStateMessages[0].route
  ) {
    return null;
  }

  const route = props.storeStateMessages[0].route;

  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        {/* Linie, die alle Punkte verbindet */}
        <polyline
          points={route.map((point) => `${point.x},${point.y}`).join(" ")}
          stroke="grey"
          strokeWidth="0.001"
          fill="none"
        />
      </svg>

      {/* Entrance Komponente */}
      {route.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: `${route[0].y * 100}%`, // Skalierung auf Prozentbasis
            left: `${route[0].x * 100}%`,
            transform: "translate(-50%, -50%)" // Zentrieren der Komponente
          }}
        >
          <Entrance {...props} />
        </div>
      )}

      {/* Exit Komponente */}
      {route.length > 1 && (
        <div
          style={{
            position: "absolute",
            top: `${route[route.length - 1].y * 100}%`,
            left: `${route[route.length - 1].x * 100}%`,
            transform: "translate(-50%, -50%)" // Zentrieren der Komponente
          }}
        >
          <Exit {...props} />
        </div>
      )}
    </>
  );
}
