import { CoordinatePoint } from "../models/messageSpec";

export function coordinatePointToString(
  coordinatePoint: CoordinatePoint
): string {
  return `${coordinatePoint.x}:${coordinatePoint.y}`;
}

export function stringToCoordinatePoint(string: string): CoordinatePoint {
  const [x, y] = string.split(":").map(Number);
  return { x, y };
}

export function addPoints(
  point1: CoordinatePoint,
  point2: CoordinatePoint
): CoordinatePoint {
  return {
    x: point1.x + point2.x,
    y: point1.y + point2.y
  };
}

export function scalarMultiplication(
  point: CoordinatePoint,
  scalar: number
): CoordinatePoint {
  return {
    x: point.x * scalar,
    y: point.y * scalar
  };
}
